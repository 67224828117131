import React from "react";
import { TextArticle, TitleBar } from "components";
import { Link } from "react-router-dom";
import { headerWithText } from "images";

const ReportSuccess = () => {
  return (
    <>
      <TitleBar img={headerWithText} />
      <div className="Page">
        <TextArticle title="Einzelfall erfolgreich übermittelt!">
          <p>
            Vielen Dank für das Melden des Vorfalls.{" "}
            <Link to="/report">Hier</Link> kannst du einen weiteren Fall melden.
          </p>
        </TextArticle>
      </div>
    </>
  );
};

export default ReportSuccess;
