import React from "react";
import { Link } from "react-router-dom";

export const IncidentNotFound = ({ className }) => (
  <article className={"Incident tile" + (className ? " " + className : "")}>
    <div className="Incident__Title">
      <h4>404 - Nicht gefunden</h4>
      <div className="Meta"></div>
    </div>

    <div className="Incident__Content">
      <div className="Incident__Teaser">
        <p>
          Leider konnte der Vorfall mit dieser ID nicht gefunden werden.
          <br />
          <Link to="/">Zurück zur Hauptseite</Link>
        </p>
      </div>
    </div>
  </article>
);
