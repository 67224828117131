import React, { useEffect, useCallback, useMemo, useState } from "react";
import {
  LoadingInfo,
  TitleBar,
  SideBar,
  TextBlock,
  TextArticle,
} from "components";
import { A, Marker, StatesMap } from "components";
import { Icon } from "icons";
import { useVictimRepresentations } from "api";
import { scrollToTargetAdjusted } from "utils";
import { Link } from "react-router-dom";
import { headerWithText } from "images";
import { setImmediate } from "utils";

export const Section = ({
  title,
  children,
  expandedSection,
  setExpandedSection,
}) => (
  <section className="HelpSection" id={title}>
    <div
      className="HelpSection__Title"
      onClick={() =>
        setExpandedSection(expandedSection === title ? null : title)
      }
    >
      <h3>{title}</h3>
      <Icon name={expandedSection === title ? "compress" : "expand"} />
    </div>

    {expandedSection === title && (
      <div className="HelpSection__SubSection">{children}</div>
    )}
  </section>
);

export const Group = ({
  name,
  street,
  postal_code,
  street_number,
  city,
  link,
  phonenumber,
  email,
  description,
  type,
  faxnumber,
}) => (
  <section className="HelpGroup">
    <div
      className={
        type === "Polizeiliche Beschwerdestelle"
          ? "HelpGroup__Title polizeilicheBeschwerdestelle"
          : "HelpGroup__Title generel"
      }
    >
      {name}
      {!!type && <span className="HelpGroup__Title__SubTitle">{type}</span>}
    </div>
    <address className="HelpGroup__Content adjusted-typographic">
      {description && <p className="HelpGroup__Text">{description}</p>}
      {(!!street || !!street_number || !!postal_code || !!city) && (
        <p className="Address">
          <small>Adresse</small>
          <br />
          <span>
            {street && street} {street_number && street_number}{" "}
            {street && <br />}
            {postal_code} {city}
          </span>
        </p>
      )}
      {!!phonenumber && (
        <p>
          <small>Telefon</small>
          <br />
          <span className="HelpGroup__PhoneNumber">{phonenumber}</span>
        </p>
      )}
      {!!faxnumber && (
        <p>
          <small>Fax</small>
          <br />
          <span className="HelpGroup__Fax">{faxnumber}</span>
        </p>
      )}
      {!!email && (
        <p>
          <small>Email</small>
          <br />
          <span>
            <A href={"mailto:" + email} className="HelpGroup__Email">
              {email}
            </A>
          </span>
        </p>
      )}
      {!!link && (
        <p>
          <small>Web</small>
          <br />
          <span>
            <A
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="HelpGroup__Link"
            >
              {link}
            </A>
          </span>
        </p>
      )}
    </address>
  </section>
);

const baseStyle = {
  stroke: "gray",
  strokeWidth: 1,
  fill: "#c20871",
  weight: 1,
  opacity: 1,
  color: "white",
  fillOpacity: 0.2,
};

const selectionStyle = {
  fillColor: "#c20871",
  stroke: "gray",
  strokeWidth: 3,
  fillOpacity: 0.35,
  weight: 2,
  fill: "#c20871",
};

const hoverStyle = {
  fill: "#b31067ee",
  weight: 0,
  stroke: "gray",
  fillOpacity: 0.7,
};

export const BaseHelp = ({ title, text, copsOrNot = false }) => {
  const [expandedSection, setExpandedSectionInner] = useState(
    window.location.hash ? window.location.hash.substring(1) : null,
  );
  const setExpandedSection = useCallback((title) => {
    setExpandedSectionInner(title);
    if (window.history?.pushState) {
      window.history.pushState(null, null, title ? "#" + title : "#");
      const node = document.getElementById(title);
      if (node) {
        setImmediate(() => scrollToTargetAdjusted(node));
      }
    }
  }, []);

  const styleCallback = (feature, hovered) => {
    if (hovered) {
      return hoverStyle;
    } else if (feature?.properties?.name === expandedSection) {
      return selectionStyle;
    }
    return baseStyle;
  };

  const [
    baseVictimRepresentations,
    loadingVictimRepresentations,
    loadedVictimRepresentations,
  ] = useVictimRepresentations();
  const victimRepresentations = useMemo(() => {
    if (baseVictimRepresentations) {
      return Object.fromEntries(
        Object.entries(baseVictimRepresentations).filter(
          ([id, vr]) => !!vr.is_cop === copsOrNot,
        ),
      );
    }
  }, [baseVictimRepresentations, copsOrNot]);

  const byState = useMemo(() => {
    if (victimRepresentations) {
      return Object.values(victimRepresentations)
        .sort((a, b) => a.order_index - b.order_index)
        .reduce(
          (a, vr) => ({
            ...a,
            [vr.state]: a[vr.state] ? [...a[vr.state], vr] : [vr],
          }),
          {},
        );
    }
  }, [victimRepresentations]);
  const states = useMemo(() => {
    let keys = Object.keys(byState);
    keys.sort();
    return keys;
  }, [byState]);

  useEffect(() => {
    if (loadedVictimRepresentations) {
      if (window.location.hash.length > 1) {
        const id = window.location.hash.substring(1);
        setImmediate(() => {
          const node = document.getElementById(id);
          if (node) {
            scrollToTargetAdjusted(node);
          }
        });
      }
    }
  }, [loadedVictimRepresentations]);

  return (
    <>
      <LoadingInfo
        loaded={loadedVictimRepresentations}
        loading={loadingVictimRepresentations}
        msg="Lädt Hilfe und Beratung"
      />
      <TitleBar img={headerWithText} />
      <div className="Page">
        <TextArticle
          title={
            !copsOrNot
              ? "Hilfe für Betroffene"
              : "Polizeilliche / Staatliche Hilfe für Betroffene"
          }
        >
          {!copsOrNot ? (
            <>
              <p>
                Wo können sich Menschen melden und beraten lassen, die
                Begegnungen mit Polizeibeamt:innen z. B. als gewaltvoll oder
                diskriminierend erlebt haben oder Zeug:in eines solchen
                Polizeieinsatzes geworden sind? Auf dieser Seite sind unabhängig
                polizeikritisch arbeitende Beratungsstellen und
                Antidiskriminierungsstellen gelistet.
              </p>

              <p>
                Diese Liste soll somit sowohl Anlaufstellen für Betroffene,
                Hilfesuchende und Zeug:innen von kontroverser Polizeipraxis
                bereitstellen als auch aufzeigen, wo es überhaupt unabhängige
                Beratungsstellen gibt und welche Unterstützungsmöglichkeiten den
                Betroffenen zur Verfügung stehen. Dabei wollen wir unbedingt
                darauf hinweisen, dass dies eine Übersicht schaffen soll und
                nicht jede Anlaufstelle für euer Anliegen passend sein muss.
              </p>

              <p>
                Haben wir eine Stelle vergessen? Schreibt uns{" "}
                <Link to="/contact">hier</Link>.
              </p>
              <p>
                Wer sich bei staatlichen Stellen melden und/oder beschweren
                möchte, finden <Link to="/help2">hier</Link> einige
                Möglichkeiten aufgeführt.
              </p>
            </>
          ) : (
            <>
              <p>
                Der Republikanische Anwaltsverein hat einige Ratschläge zum
                Umgang und zur Dokumentation der erlebten Situation für
                Betroffene{" "}
                <A href="https://www.rav.de/publikationen/rav-infobriefe/infobrief-102-2009/merkblatt-fuer-opfer-rechtswidriger-polizeigewalt/">
                  hier
                </A>{" "}
                verschriftlich.
              </p>
            </>
          )}

          {!!byState &&
            states.map((state) => (
              <Section
                title={state}
                expandedSection={expandedSection}
                setExpandedSection={setExpandedSection}
                key={state}
              >
                {byState[state].map((vr) => (
                  <Group key={vr.id} {...vr} />
                ))}
              </Section>
            ))}
        </TextArticle>
        <SideBar>
          <div className="sticky">
            <TextBlock title="Bundesweite Karte">
              <StatesMap
                zoom={5}
                onClick={({ event, payload }) =>
                  payload?.properties?.name &&
                  setExpandedSection(payload.properties.name)
                }
                styleCallback={styleCallback}
              >
                {Object.values(victimRepresentations).map(
                  (vr) =>
                    vr.coordinates && (
                      <Marker
                        key={vr.id}
                        style={{ pointerEvents: "none" }}
                        anchor={vr.coordinates
                          .split(",")
                          .map((f) => parseFloat(f))}
                        secondaryPin
                      />
                    ),
                )}
              </StatesMap>
            </TextBlock>
            <A
              href="https://weact.campact.de/petitions/deutschland-hat-ein-polizeiproblem-wir-fordern-unabhangige-beschwerdestellen"
              title="Petition"
              withReferrer
              style={{ textDecoration: "none" }}
            >
              <TextBlock
                title="Petition: Polizeivergehen - Wir fordern unabhängige Ermittlungsstellen!"
                style={{ marginTop: "20px" }}
              >
                <p style={{ marginBottom: "0px" }}>
                  Wir fordern eine unabhängige Beschwerdestelle für
                  Polizeivergehen. <u>Helft uns und unterschreibt!</u>
                </p>
              </TextBlock>
            </A>
          </div>
        </SideBar>
      </div>
    </>
  );
};

const Help = () => <BaseHelp text="Hilfe für Betroffene" />;

export default Help;
