import React from "react";
import { Routes, Route } from "routing";
import { Navigate } from "react-router-dom";
import {
  Privacy,
  CopHelp,
  IncidentPage,
  Overview,
  Imprint,
  WhoWeAre,
  Help,
  Report,
  FrequentlyAskedQuestions,
  ReportSuccess,
  Definitions,
  Contact,
  NotFound,
} from "./pages";
import { IncidentThumbnail } from "thumbnails/IncidentThumbnail";
import { Header, Footer } from "structure";
import { ScrollToTop } from "utils";

const AppInner = () => (
  <>
    <ScrollToTop />
    <Header />
    <main>
      <Routes>
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/aboutus" element={<WhoWeAre />} />
        <Route path="/help" element={<Help />} />
        <Route path="/help2" element={<CopHelp />} />
        <Route path="/report" element={<Report />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/reportsuccess" element={<ReportSuccess />} />
        <Route
          path="/incident/:id/"
          element={<IncidentPage />}
          getPossibleIds={() => {
            return {
              id: window.__data["useIncidents"].map((incident) => incident.id),
            };
          }}
        />
        <Route
          path="/incident/:id/first"
          element={<IncidentThumbnail />}
          getPossibleIds={() => {
            return {
              id: window.__data["useIncidents"].map((incident) => incident.id),
            };
          }}
        />
        <Route path="/faq/" element={<FrequentlyAskedQuestions />} />
        <Route path="/definitions" element={<Definitions />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/:visualizer/:page/"
          element={<Overview />}
          getPossibleIds={() => {
            return {
              page: [
                ...new Array(
                  Math.ceil(window.__data["useIncidents"].length / 10)
                ).keys(),
              ],
              visualizer: ["map", "text"],
            };
          }}
        />
        <Route
          path="/:visualizer/"
          element={<Overview />}
          getPossibleIds={() => ({ visualizer: ["map", "text"] })}
        />
        <Route path="/notfound" element={<NotFound />} />
        <Route path="/" index element={<Overview />} />
        <Route path="*" element={<Navigate to="/notfound" />} />
      </Routes>
    </main>
    <Footer />
  </>
);

const App = () => (
  <Routes>
    <Route
      path="/incident/:id/first"
      element={<IncidentThumbnail version="first" />}
      getPossibleIds={() => {
        return {
          id: window.__data["useIncidents"].map((incident) => incident.id),
        };
      }}
      thumbnail
    />
    <Route
      path="/incident/:id/second"
      element={<IncidentThumbnail version="second" />}
      getPossibleIds={() => {
        return {
          id: window.__data["useIncidents"].map((incident) => incident.id),
        };
      }}
      thumbnail
    />
    <Route
      path="/incident/:id/third"
      element={<IncidentThumbnail version="third" />}
      getPossibleIds={() => {
        return {
          id: window.__data["useIncidents"].map((incident) => incident.id),
        };
      }}
      thumbnail
    />
    <Route
      path="/incident/:id/fourth"
      element={<IncidentThumbnail version="fourth" />}
      getPossibleIds={() => {
        return {
          id: window.__data["useIncidents"].map((incident) => incident.id),
        };
      }}
      thumbnail
    />
    <Route path="/*" element={<AppInner />} />
  </Routes>
);

export default App;
