import React from "react";
import { TextArticle, TitleBar } from "components";
import { headerWithText } from "images";

const NotFound = () => {
  return (
    <>
      <TitleBar img={headerWithText} />
      <div className="Page">
        <TextArticle title="404 - Nichts gefunden">
          <p>
            Leider wurde die gewünschte Addresse nicht gefunden - Aber
            versprochen das ist ein Einzelfall.
          </p>
        </TextArticle>
      </div>
    </>
  );
};

export default NotFound;
