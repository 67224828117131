import React from "react";
import { Icon } from "icons";

export const LoadingInfo = ({ loading, loaded, msg }) => (
  <div
    className={
      "Loading" + (!loading || loaded ? " Loading--hidden" : " Loading--active")
    }
  >
    <div className="Container">
      <Icon name="loading" spin />
      {msg ? msg : "Lädt Einzelfälle"}
    </div>
  </div>
);
