import React from "react";
import { Icon } from "icons";

export const AppError = ({ error }) => {
  let version = null;
  if (process.env.REACT_APP_GIT_VERSION) {
    version = JSON.parse(process.env.REACT_APP_GIT_VERSION);
  }

  return (
    <div className="AppError">
      <div className="AppError__Header">
        <Icon name="error" />
        <h2>Ein schwerwiegender Fehler ist aufgetreten</h2>
      </div>
      <div className="AppError__Content">
        <p>
          <b>Du willst uns helfen, diesen Fehler zu beheben?</b>
          <br />
          Dann schreib uns eine Email an admin@copservation.de.
          <br />
          Bitte schildere uns dafür kurz, was du auf der Seite versucht hast zu
          machen sowie die URL und die Uhrzeit. Füge bestenfalls noch alle
          Informationen hinzu, die in der untenstehenden Box zu finden sind. Wir
          versuchen dann, den Fehler so bald wie möglich zu beheben.
          <br />
          Danke für deine Mithilfe!
        </p>
      </div>
      <div className="AppError__Content">
        <pre>
          URL: {window.location.href}
          <br />
          Time: {new Date().toISOString()}
          <br />
          Message: {error.message}
          <br />
          {version && (
            <>
              {version.hash && (
                <>
                  {`Hash: ${version.hash}`}
                  <br />
                </>
              )}
              {version.branch && (
                <>
                  {`Branch: ${version.branch}`}
                  <br />
                </>
              )}
              {version.tag && (
                <>
                  {`Tag: ${version.tag}`}
                  <br />
                </>
              )}
              {version.date && (
                <>
                  {`BuildDate: ${version.date}`}
                  <br />
                </>
              )}
            </>
          )}
          <br />
          {!!error.stack && <>Stack: {error.stack}</>}
        </pre>
      </div>
    </div>
  );
};
