import React, { useEffect, useRef, useState } from "react";
import Translate from "Translate";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Icon } from "icons";
import { A } from "components";

const Navigation = () => {
  const menuRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const onClick = () => setIsActive(!isActive);

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1200 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1199 });

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (menuRef.current !== null && !menuRef.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    };

    if (isActive) {
      window.addEventListener("click", pageClickEvent);
    }

    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [isActive]);

  return (
    <div className="menu-wrapper">
      {isDesktopOrLaptop && (
        <>
          <nav className="main-menu--desktop">
            <ul className="menu-list">
              <li>
                <NavLink to="/">
                  <Translate k="link.overview" />
                </NavLink>
              </li>
              <li>
                <A
                  href="https://weact.campact.de/petitions/deutschland-hat-ein-polizeiproblem-wir-fordern-unabhangige-beschwerdestellen"
                  title="Petition"
                  withReferrer
                >
                  Petition
                </A>
              </li>
              <li>
                <NavLink to="/aboutus">
                  <Translate k="link.aboutUs" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/report">
                  <Translate k="link.report" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/help">
                  <Translate k="link.help" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/faq">
                  <Translate k="link.faq" />
                </NavLink>
              </li>
            </ul>
          </nav>
        </>
      )}

      {isTabletOrMobile && (
        <>
          <button onClick={onClick} className="menu-trigger" title="Menü">
            <span className="menu-icon">
              {/* @todo maybe switch in some proper svg file here */}
              &#9776;
            </span>
          </button>
          <nav
            ref={menuRef}
            className={`main-menu ${isActive ? "active" : "inactive"}`}
          >
            <div className="arrow-up"></div>
            <ul className="menu-list" onClick={onClick}>
              <li>
                <NavLink to="/">
                  <Translate k="link.overview" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/aboutus">
                  <Translate k="link.aboutUs" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/report">
                  <Translate k="link.report" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/help">
                  <Translate k="link.help" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/faq">
                  <Translate k="link.faq" />
                </NavLink>
              </li>
              <li>
                <ul className="social-media">
                  <li>
                    <A
                      href="https://twitter.com/cop_servation"
                      title="Twitter"
                      className="link--twitter"
                    >
                      <Icon name="twitter" />
                    </A>
                  </li>
                  <li>
                    <A
                      href="https://www.instagram.com/copservation/"
                      title="Instagram"
                      className="link--instagram"
                    >
                      <Icon name="instagram" />
                    </A>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </>
      )}
    </div>
  );
};

export default Navigation;
