import React from "react";
import { Route as BaseRoute, Routes as BaseRoutes } from "react-router-dom";

export const getRoute = ({
  element,
  path,
  getPossibleIds,
  thumbnail = false,
}) => {
  if (window?.routeListener) {
    window.routeListener(path, getPossibleIds, thumbnail);
  }

  return <BaseRoute element={element} path={path} />;
};

export const Route = ({ element, path, getPossibleIds }) => {
  return getRoute({ element, path, getPossibleIds });
};

export const Routes = ({ children }) => (
  <BaseRoutes>
    {React.Children.map(children, (child) => {
      if (!child?.type || child.type !== Route) {
        throw new Error("Routes must only contain Route components");
      }
      return getRoute(child.props);
    })}
  </BaseRoutes>
);
