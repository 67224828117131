import React from "react";
import { Link } from "react-router-dom";
import Translate from "Translate";
import { Location } from "./Location";
import { Icon } from "icons";
import { Hashtags } from "./TagButton";
import { ArticleButton } from "./ArticleButton";
import { FilterDateTime } from "./FilterDateTime";
import { IncidentButton } from "./IncidentButton";
import ReactMarkdown from "react-markdown";

const TwitterShareLink = ({ text, url, tags, titleText }) => (
  <a
    className="NoArrow"
    target="_blank"
    rel="noopener noreferrer"
    href={`http://twitter.com/share?text=${encodeURIComponent(
      text,
    )}&url=${encodeURIComponent(url)}&hashtags=${encodeURIComponent(tags)}`}
    title={`${titleText}`}
  >
    <Icon name="twitter" />
  </a>
);

export const Incident = ({ className, incident, closeHandler = null }) => (
  <article
    className={
      (!!closeHandler ? "Incident Incident--popup" : "Incident tile") +
      (className ? " " + className : "")
    }
    key={incident.id}
  >
    <div className="Incident__Title">
      <Link to={"/incident/" + (incident.slug ? incident.slug : incident.id)}>
        <h4>{incident.title}</h4>
      </Link>
      {!!closeHandler && (
        <div
          className="Meta"
          onClick={(evt) => {
            evt.stopPropagation();
            closeHandler();
          }}
        >
          <big>×</big>
        </div>
      )}
    </div>

    <div className="Incident__Content">
      <div className="Incident__Teaser">
        <p>
          <ReactMarkdown>{incident.teaser}</ReactMarkdown>
        </p>
      </div>

      <div className="Incident__Extras Extras">
        <table>
          <tbody>
            <tr>
              <th>Ort</th>
              <td>
                <Location
                  place={incident.place}
                  state={incident.state}
                  county={incident.county}
                  community={incident.community}
                  postalCode={incident.postal_code}
                  coordinates={incident.coordinates}
                  precision={incident.precision}
                />
              </td>
            </tr>
            <tr>
              <th>Zeitpunkt</th>
              <td>
                <FilterDateTime
                  date={incident.incident_date}
                  precision={incident.incident_date_precision}
                />
              </td>
            </tr>
            <tr>
              <th>
                <Translate k="incident.sourceTitle" />
              </th>
              <td>
                {incident.articles &&
                  incident.articles.map((article) => (
                    <ArticleButton key={article.id} article={article} />
                  ))}
              </td>
            </tr>
            {incident.root_incident_id && (
              <tr>
                <th>Ursprungsfall</th>
                <td>
                  <IncidentButton
                    incident={{ id: incident.root_incident_id }}
                  />
                </td>
              </tr>
            )}
            {incident.similiar_incidents &&
              incident.similiar_incidents.length > 0 && (
                <tr>
                  <th>Verknüpfte Vorfälle</th>
                  <td>
                    {incident.similiar_incidents.map((item) => (
                      <IncidentButton incident={item} key={item.id} />
                    ))}
                  </td>
                </tr>
              )}
            {incident.leaf_incidents && incident.leaf_incidents.length > 0 && (
              <tr>
                <th>Folgende Vorfälle</th>
                <td>
                  {incident.leaf_incidents.map((item) => (
                    <IncidentButton incident={item} key={item.id} />
                  ))}
                </td>
              </tr>
            )}
            {incident.hashtags && incident.hashtags.length > 0 && (
              <tr>
                <th>Kontexte</th>
                <td>
                  <Hashtags items={incident.hashtags} />
                </td>
              </tr>
            )}
            <tr>
              <th>Stand</th>
              <td>
                <FilterDateTime date={incident.pub_date} precision={1} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div className="Incident__SocialMedia">
      <a
        className="NoArrow"
        target="_blank"
        rel="noopener noreferrer"
        href={`/incident/${incident.id}`}
      >
        <span className="hashtag">#cps_de{incident.id}</span>
      </a>

      {incident.slug && (
        <a
          className="NoArrow"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://copservation.de/incident/${incident.id}`}
        >
          <span className="hashtag">{`#cps_de${incident.slug}`}</span>
        </a>
      )}

      <TwitterShareLink
        text={incident.title}
        tags={
          `copservation,cps_de${incident.id}` +
          (incident.slug ? ",cps_de" + incident.slug : "")
        }
        url={`https://copservation.de/incident/${incident.id}`}
        titleText="Diesen Vorfall auf Twitter teilen"
      />

      <a
        className="NoArrow"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/copservation/"
        title="Folge Copservation auf Instagram"
      >
        <Icon name="instagram" />
      </a>
    </div>
  </article>
);
