import React, { useMemo, useState, useCallback, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
import { useFilteredIncidents } from "data";
import moment from "moment";
import {
  BaseMap,
  Marker,
  LoadingInfo,
  TextArticle,
  TitleBar,
} from "components";
import { locationPrecisions, datePrecisions } from "../constants";
import { Icon } from "icons";
import {
  useHashtags,
  useIncidents,
  useGermanyGeoJson,
  useStatesGeoJson,
  useDistrictsGeoJson,
} from "api";
import { Link } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";
import addDays from "date-fns/addDays";
import { GeoJson } from "pigeon-maps";
import { headerWithText } from "images";

registerLocale("de", de);

// use this if a quick and dirty solution is needed
// even so, the build process must be updated with --legacy-peer-deps because of react version missmatch
// import ClientCaptcha from "react-client-captcha";

const PrivacyCheckbox = ({ privacyAccepted, setPrivacyAccepted }) => {
  // (ev) => setPrivacyAccepted(!privacyAccepted)
  return (
    <div>
      <input
        type="checkbox"
        onChange={(ev) => setPrivacyAccepted(ev.target.checked)}
        defaultChecked={false}
        id="accept_checkbox"
      />
      <label htmlFor="accept_checkbox">
        Ich habe die{" "}
        <Link to="/privacy" target="_blank">
          Datenschutzerklärung
        </Link>{" "}
        gelesen und akzeptiere diese.
      </label>
    </div>
  );
};

export const InputHeading = ({ heading, helpText, children }) => (
  <div className="Help">
    <span className="Help__Icon">
      <Icon name="help" />
    </span>
    <div className="Popup">
      <div className="Popup__Content">
        <span className="Popup__Content__Text">{children}</span>
      </div>
    </div>
  </div>
);

const DateTimeInput = ({
  datePrecision,
  setDatePrecision,
  date,
  setDate,
  time,
  setTime,
  errors,
}) => {
  const formats = [
    "dd.MM.yyyy, HH:mm",
    "dd.MM.yyyy",
    "dd.MM.yyyy",
    "MMMM yyyy",
    "yyyy",
  ];

  return (
    <div className="ReportInput">
      <div className="Title">
        <h3>Zeitpunkt (optional)</h3>
        <InputHeading>
          <p>
            Wähle links die Genauigkeit des Zeitpunktes (Uhrzeit, Tag, Woche,
            Monat, Jahr) aus und rechts den Zeitpunkt.
          </p>
        </InputHeading>
      </div>
      {/* <div className="ReportInput Title">
                <div className="Icon">
                    <Icon name="calendar"/>
                </div>
                <label for="report-form__zeitpunkt">
                    Zeitpunkt
                </label>
                <InputHeading>
                    <p>
                        Wähle links die Genauigkeit des Zeitpunktes aus und rechts den Zeitpunk.
                        Bei der Genauigkeit <strong>Woche</strong> wähle den nähesten Wochentag aus.
                    </p>
                </InputHeading>
            </div> */}
      <div className="Input Element">
        <select
          className="DatePlaceInput"
          value={datePrecision}
          onChange={(ev) => setDatePrecision(ev.target.value)}
          id="report-form__zeitpunkt"
        >
          {Object.keys(datePrecisions).map((key) => (
            <option key={key} value={key}>
              {datePrecisions[key].name}
            </option>
          ))}
        </select>
        <div className="grow DatePicker">
          <DatePicker
            dateFormat={formats[Number(datePrecision)]}
            timeFormat="HH:mm"
            locale="de"
            selected={date}
            onChange={(date_) => setDate(date_)}
            popperClassName="date-picker-over-map"
            showWeekNumbers
            showTimeSelect={datePrecision === "0" ? true : false}
            showMonthYearPicker={datePrecision === "3" ? true : false}
            showYearPicker={datePrecision === "4" ? true : false}
            minDate={new Date(1990, 0, 1)}
            maxDate={addDays(new Date(), 1)}
            showMonthDropdown
            showYearDropdown
            // scrollableYearDropdown
            dropdownMode="select"
          />
        </div>
        {/* <input
                    className="grow DatePlaceInput"
                    type="date"
                    onChange={(ev) => setDate(ev.target.value)}
                    value={date || ''}
                />
                <input
                    className="grow DatePlaceInput"
                    type={datePrecision === "0" ? "time" : "hidden"}
                    onChange={(ev) => setTime(ev.target.value)}
                    value={time || ''}
                /> */}
      </div>
      <div className="Input Element">
        <span className="Error">{errors["date"]}</span>
      </div>
      <div className="Input Element">
        <span className="Error">{errors["time"]}</span>
      </div>
    </div>
  );
};

const PlaceInput = ({
  locationPrecision,
  setLocationPrecision,
  placeInputText,
  placeInputPlaceHolder,
  errors,
}) => (
  <div className="ReportInput">
    <div className="Title">
      <h3>Ort (optional)</h3>
      <InputHeading>
        <p>
          Wähle links die Genauigkeit des Ortes (Bundesland, Landkreis, Stadt,
          Stadtteil) aus und rechts bzw. auf der Karte den Ort. Für das Setzen
          des Pins markiere bitte den Ort durch Klicken auf die Karte.
        </p>
      </InputHeading>
    </div>

    {/* <div className="ReportInput Title">
            <div className="Icon">
                <Icon name="marker"/>
            </div>
            <label for="report-form__ort">
                Ort
            </label>
            <InputHeading>
                <p>
                    Wähle links die Genauigkeit des Ortes aus und
                    rechts bzw. auf der Karte den Ort. Für lokale
                    Ortsangaben (<strong>Stadt</strong>, <strong>Stadtteil</strong>, <strong>Straße</strong>, <strong>Punktgenau</strong>) setze Bitte den Ort
                    durch Klicken auf die Karte.
                </p>
            </InputHeading>
        </div> */}
    <div className="Input Element">
      <select
        className="DatePlaceInput"
        value={locationPrecision}
        onChange={(ev) => setLocationPrecision(ev.target.value)}
        id="report-form__ort"
      >
        {Object.keys(locationPrecisions).map((key) => (
          <option key={key} value={key}>
            {locationPrecisions[key]}
          </option>
        ))}
      </select>
      <input
        className="grow DatePlaceInput"
        type="textbox"
        value={placeInputText}
        placeholder={placeInputPlaceHolder}
        readOnly="readonly"
      />
    </div>
    <div className="Input Element Error">
      <span className="Error">{errors["place"]}</span>
    </div>
  </div>
);

const baseStyle = {
  fill: "#c20871",
  weight: 1,
  opacity: 1,
  stroke: "white",
  strokeDasharray: "3",
  fillOpacity: 0.4,
};

const selectionStyle = {
  fill: "#c20871",
  fillOpacity: 0.7,
  weight: 0.5,
  color: "#c20871",
};

const hoverStyle = {
  fill: "#b31067ee",
  weight: 0,
  // opacity: 1,
  // color: 'gray',
  // dashArray: '0',
  fillOpacity: 0.7,
};

const Map = (props) => {
  const {
    locationPrecision,
    latlng,
    setLatLng,
    incidentsList,
    germanyGeoJson,
    statesGeoJson,
    districtsGeoJson,
    setPlaceInputText,
  } = props;

  const [selectedLayer, setSelectedLayer] = useState(null);

  return (
    <div className="Input Element">
      <BaseMap
        incidents={incidentsList}
        height={300}
        onClick={(ev) => {
          setLatLng(ev.latLng);
          setPlaceInputText(
            ev.latLng[0].toFixed(3) + ", " + ev.latLng[1].toFixed(3),
          );
        }}
      >
        {Number(locationPrecision) === 0 && germanyGeoJson.features && (
          <GeoJson
            svgAttributes={baseStyle}
            styleCallback={(feature, hover) =>
              selectedLayer === feature
                ? selectionStyle
                : hover
                ? hoverStyle
                : baseStyle
            }
            onClick={({ event, payload, anchor }) => {
              setPlaceInputText(payload.properties.name);
              setSelectedLayer(payload);
              setLatLng(anchor);
            }}
            data={germanyGeoJson}
          />
        )}
        {Number(locationPrecision) === 1 && statesGeoJson.features && (
          <GeoJson
            svgAttributes={baseStyle}
            styleCallback={(feature, hover) =>
              selectedLayer === feature
                ? selectionStyle
                : hover
                ? hoverStyle
                : baseStyle
            }
            onClick={({ event, payload, anchor }) => {
              setPlaceInputText(payload.properties.name);
              setSelectedLayer(payload);
              setLatLng(anchor);
            }}
            data={statesGeoJson}
          />
        )}
        {Number(locationPrecision) === 2 && (
          <GeoJson
            svgAttributes={baseStyle}
            styleCallback={(feature, hover) =>
              selectedLayer === feature
                ? selectionStyle
                : hover
                ? hoverStyle
                : baseStyle
            }
            onClick={({ event, payload, anchor }) => {
              setPlaceInputText(payload.properties.NAME_3);
              setSelectedLayer(payload);
              setLatLng(anchor);
            }}
            data={districtsGeoJson}
          />
        )}
        {Number(locationPrecision) > 2 && <Marker anchor={latlng} />}
      </BaseMap>
    </div>
  );
};

const HashtagInput = ({ hashtagData, hashtags, setHashtags }) => {
  const hashtagList = Object.keys(hashtagData)
    .reduce((a, key) => [...a, hashtagData[key]], [])
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="ReportInput">
      {/* <div className="ReportInput Title">
                <div className="Icon">
                    <Icon name="category"/>
                </div>
                <label for="report-form__kontexte">
                    Kontexte
                </label>
                <InputHeading>
                    <p>Einordnung des Falls in größere Kontexte.</p>
                </InputHeading>
            </div> */}
      <div className="Title">
        <h3>Einordung des Vorfalls (optional)</h3>
        <InputHeading>
          <p>
            Kontexte bieten die Möglichkeit, die in den Artikeln beschriebenen
            Umstände oder Hintergründe mit Schlagwörtern zu beschreiben.
          </p>
        </InputHeading>
      </div>
      <div>
        <p>
          Hier kannst du dem Vorfall einen passenden Kontext zuordnen. Eine
          Liste aller Kontexte findest du{" "}
          <Link to="/definitions" target="_blank">
            hier
          </Link>
        </p>
      </div>
      <div className="Input Element">
        <Multiselect
          selectedValues={hashtags}
          options={hashtagList}
          displayValue="name"
          onSelect={setHashtags}
          onRemove={setHashtags}
          placeholder="Wähle die Kontexte"
          id="report-form__kontexte"
        />
      </div>
      <div className="Input Element Error">
        <span className="Error" />
      </div>
    </div>
  );
};

const SourceInput = ({ sources, setSources, errors }) => (
  <div className="ReportInput">
    {/* <div className="ReportInput Title">
            <div className="Icon">
                <Icon name="news"/>
            </div>
            <label for="report-form__quellen">
                Quellen
            </label>
            <InputHeading>
                <p>Liste alle relevanten Quellen. Bitte nur öffentlich zugängliche Links.</p>
            </InputHeading>
        </div> */}
    <div className="Title">
      <h3>Quellen</h3>
      <InputHeading>
        <p>Bitte liste hier alle relevanten Quellen der Artikel auf.</p>
      </InputHeading>
    </div>
    <div>
      <p>Bitte gib hier die Quellen an.</p>
    </div>
    <div className="Input Element">
      <textarea
        className="grow"
        onChange={(ev) => setSources(ev.target.value)}
        value={sources}
        placeholder="https://..."
        id="report-form__quellen"
      />
    </div>
    <div className="Input Element Error">
      <span className="Error">{errors["sources"]}</span>
    </div>
  </div>
);

const TeaserInput = ({ teaser, setTeaser }) => (
  <div className="ReportInput">
    {/* <div className="ReportInput Title">
            <div className="Icon">
                <Icon name="teaser"/>
            </div>
            <label for="report-form__teaser-text">
                Teasertext
            </label>
            <InputHeading>
                <p>
                    Ein optinaler Teaserttext, welcher später beim Vorfall
                    auf der Karte angezeigt wird. Dieser Text sollte den Fall
                    kurz zusammenfassen.
                </p>
            </InputHeading>
        </div> */}
    <div className="Title">
      <h3>Teasertext (optional)</h3>
      <InputHeading>
        <p>
          Der Teasertext wird beim Vorfall auf der Karte angezeigt und sollte
          den Vorfall kurz und mit eigenen Worten zusammenfassen.
        </p>
      </InputHeading>
    </div>
    <div>
      <p>Dieser Text sollte den Vorfall kurz zusammenfassen.</p>
    </div>
    <div className="Input Element">
      <textarea
        className="grow"
        onChange={(ev) => setTeaser(ev.target.value)}
        value={teaser}
        placeholder="Teasertext"
        id="report-form__teaser-text"
      />
    </div>
    <div className="Input Element Error">
      <span className="Error" />
    </div>
  </div>
);

const MsgInput = ({ msg, setMsg }) => (
  <div className="ReportInput">
    {/* <div className="ReportInput Title">
            <div className="Icon">
                <Icon name="envelope"/>
            </div>
            <label for="report-form__nachricht">
                Nachricht
            </label>
            <InputHeading>
                <p>Deine Nachricht an uns.</p>
            </InputHeading>
        </div> */}
    <div className="Title">
      <h3>Zusätzliche Infos (optional)</h3>
      <InputHeading>
        <p>Deine Nachricht an uns.</p>
      </InputHeading>
    </div>
    <div>
      <p>
        Es fehlt ein Kontext oder du möchtest uns etwas anderes mitteilen?
        Schreib es uns!
      </p>
    </div>
    <div className="Input Element">
      <textarea
        className="grow"
        onChange={(ev) => setMsg(ev.target.value)}
        value={msg}
        placeholder="Deine Nachricht"
        id="report-form__nachricht"
      />
    </div>
  </div>
);

const validateInput = (
  setErrors,
  date,
  time,
  datePrecision,
  latlng,
  locationPrecision,
  hashtags,
  sources,
  privacyAccepted,
  captchaCode,
  captchaInput,
) => {
  let errors = {};
  let formIsValid = true;

  // date validation
  // if (date !== null) {
  //     let date_obj = moment(date);

  //     let minDate = new Date(1990, 0, 0);
  //     let maxDate = moment(new Date()).add(1, 'd');

  //     if (date_obj < minDate) {
  //         formIsValid = false;
  //         errors["date"] = `Der Vorfall muss sich nach dem ${moment(minDate).format("DD.MM.YYYY")} ereignet haben.`;
  //     }

  //     if (date_obj > maxDate) {
  //         formIsValid = false;
  //         errors["date"] = "Der Vorfall kann nicht in der Zukunft liegen.";
  //     }
  // } else {
  //     formIsValid = false;
  //     errors["date"] = "Bitte gebe ein gültiges Datum an.";
  // }

  // if (datePrecision === "0") {
  //     if (time) {
  //         let time_obj = parseTime(time);

  //         if (!time_obj.isValid()) {
  //             formIsValid = false;
  //             errors["time"] = "Bitte gebe eine gültige Uhrzeit an.";
  //         }
  //     } else {
  //         formIsValid = false;
  //         errors["time"] = "Bitte gebe eine Uhrzeit an.";
  //     }
  // }

  // place validation
  // if (!latlng) {
  //     formIsValid = false;

  //     switch (locationPrecision) {
  //         case "0":
  //             break;
  //         case "1":
  //             errors["place"] = "Bitte wähle ein Bundesland aus.";
  //             break;
  //         case "2":
  //             errors["place"] = "Bitte wähle einen Landkreis aus.";
  //             break;
  //         default:
  //             errors["place"] = "Bitte setze einen Marker durch klicken auf die Karte.";
  //     }
  // }

  // hashtag / context validation
  // if (!hashtags.length) {
  //     formIsValid = false;
  //     errors["hashtags"] = "Wähle mindestens eine Kategorie.";
  // }

  // source validation
  if (!sources.length) {
    formIsValid = false;
    errors["sources"] = "Ohne Quellen geht es nicht.";
  }

  // captcha validation
  // if (!captchaInput.length) {
  //     formIsValid = false;
  //     errors["captcha"] = "Bitte captcha eingeben.";
  // } else {
  //     if (captchaCode !== captchaInput) {
  //         formIsValid = false;
  //         errors["captcha"] = "Captcha ist falsch. Bitte probiere es erneut";
  //     }
  // }

  if (!privacyAccepted) {
    formIsValid = false;
    errors["privacy"] =
      "Bitte lese und akzeptiere unsere Datenschutzerklärung.";
  }

  if (!formIsValid) {
    errors["submit"] = "Bitte überprüfe deine Eingaben.";
  }
  setErrors(errors);
  return formIsValid;
};

const Form = ({
  incidents,
  hashtagData,
  germanyGeoJson,
  statesGeoJson,
  districtsGeoJson,
}) => {
  // date and time
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(null);
  const [datePrecision, setDatePrecision] = useState("1");

  // place
  const latlngGermany = useMemo(() => [51.0834196, 10.4234469], []);

  const [locationPrecision, setLocationPrecision] = useState("0");
  const [latlng, setLatLng] = useState(latlngGermany);
  const [placeInputText, setPlaceInputText] = useState("Deutschland");
  const [placeInputPlaceHolder, setPlaceInputPlaceHolder] = useState("");

  const datePrecisionsInDays = datePrecisions[datePrecision]
    ? datePrecisions[datePrecision].days
    : 3;
  const dateRange = useMemo(
    () =>
      date
        ? [
            moment(date).subtract(datePrecisionsInDays, "d"),
            moment(date).add(datePrecisionsInDays, "d"),
          ]
        : null,
    [date, datePrecisionsInDays],
  );

  // reset placeInputText and latlng if locationPrecision changes
  useEffect(() => {
    switch (locationPrecision) {
      case "0":
        setPlaceInputPlaceHolder("");
        break;
      case "1":
        setPlaceInputPlaceHolder("[Wähle Bundesland auf der Karte]");
        break;
      case "2":
        setPlaceInputPlaceHolder("[Wähle Landkreis auf der Karte]");
        break;
      default:
        setPlaceInputPlaceHolder("[Setze Ort auf durch Klick auf der Karte]");
    }

    if (locationPrecision === "0") {
      setLatLng(latlngGermany);
      setPlaceInputText("Deutschland");
    } else {
      setPlaceInputText("");
      setLatLng(null);
    }
  }, [locationPrecision, latlngGermany]);

  const incidentsList = useFilteredIncidents({ incidents, dateRange });

  // categories and hashtags
  const [hashtags, setHashtags] = useState([]);

  // sources and teaser
  const [sources, setSources] = useState("");
  const [teaser, setTeaser] = useState("");

  // msg
  const [msg, setMsg] = useState("");

  // privacy
  const [privacyAccepted, setPrivacyAccepted] = useState(false);

  // captcha
  //const [captchaInput, setCaptchaInput] = useState("");
  const captchaInput = null;
  //const [captchaCode, setCaptchaCode] = useState("");
  const captchaCode = null;

  // error dict
  const [errors, setErrors] = useState({});

  const [submitSuccessful, setSubmitSuccessful] = useState(false);

  const submitHandler = useCallback(
    (ev) => {
      ev.preventDefault();

      if (
        validateInput(
          setErrors,
          date,
          time,
          datePrecision,
          latlng,
          locationPrecision,
          hashtags,
          sources,
          privacyAccepted,
          captchaCode,
          captchaInput,
        )
      ) {
        let place = "";

        if (Number(locationPrecision) < 3) {
          place = placeInputText;
        }

        const data = {
          date,
          time,
          datePrecision,

          locationPrecision,
          latlng,
          place,

          hashtags,

          sources,
          teaser,

          msg,
        };

        fetch(process.env.REACT_APP_REPORT_INCIDENT_URL, {
          //
          method: "POST",
          // mode: 'no-cors',
          cache: "no-cache",
          credentials: "same-origin",
          headers: new Headers({ "Content-Type": "application/json" }),
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify(data), // body data type must match "Content-Type" header
        }).then(
          (response) => {
            // setLoadingState({loaded: true});
            if (response.ok) {
              setSubmitSuccessful(true);
            } else {
              setErrors({
                submit:
                  "Es ist ein Fehler beim Übermitteln der Daten aufgetreten. Bitte probiere es später erneut.",
              });
            }
            console.log(response);
          },
          (error) => {
            // setLoadingState({error: true});
            setErrors({
              submit:
                "Es ist ein Fehler beim Übermitteln der Daten aufgetreten. Bitte probiere es später erneut.",
            });
            console.log(error);
          },
        );
      } else {
        // setCaptchaInput("");
      }
    },
    [
      date,
      time,
      datePrecision,
      latlng,
      locationPrecision,
      placeInputText,
      hashtags,
      sources,
      teaser,
      msg,
      privacyAccepted,
      captchaCode,
      captchaInput,
    ],
  );

  if (submitSuccessful) {
    return <Navigate to="/reportsuccess" replace />;
  }

  return (
    <>
      <form onSubmit={submitHandler}>
        <div className="InputGroup">
          {/* <h3>Quellen</h3> */}
          {/* <p>
                    Bitte gib hier die Quellen an.
                </p> */}
          <div className="InputSubGroup">
            <SourceInput
              sources={sources}
              setSources={setSources}
              errors={errors}
            />
          </div>
        </div>

        <div className="InputGroup">
          {/* <h3>Teasertext (optional)</h3>
                <p>
                    Dieser Text sollte den Vorfall kurz zusammenfassen.
                </p> */}
          <div className="InputSubGroup">
            <TeaserInput teaser={teaser} setTeaser={setTeaser} />
          </div>
        </div>

        <div className="InputGroup">
          {/* <h3>Zeitpunkpunkt & Ort</h3> */}
          {/* <p>Wann und wo ist es zu dem Vorfall gekommen</p> */}

          <div className="InputSubGroup InputSubGroup--together">
            <DateTimeInput
              datePrecision={datePrecision}
              setDatePrecision={setDatePrecision}
              date={date}
              setDate={setDate}
              time={time}
              setTime={setTime}
              errors={errors}
            />
            <PlaceInput
              locationPrecision={locationPrecision}
              setLocationPrecision={setLocationPrecision}
              placeInputText={placeInputText}
              placeInputPlaceHolder={placeInputPlaceHolder}
              errors={errors}
            />
          </div>
          <Map
            locationPrecision={locationPrecision}
            latlng={latlng}
            setLatLng={setLatLng}
            incidentsList={incidentsList}
            germanyGeoJson={germanyGeoJson}
            statesGeoJson={statesGeoJson}
            districtsGeoJson={districtsGeoJson}
            setPlaceInputText={setPlaceInputText}
          />
        </div>
        <div className="InputGroup margin-top-38">
          {/* <h3>Einordung des Vorfalls</h3>
                <p>
                    Eine Liste aller möglichen Einordnungsmöglichkeiten (Kontexte) findes Du <Link to="/definitions" target="_blank">hier</Link>.
                    Sollte ein Kontext fehlen, kannst Du uns das weiter unten mitteilen.
                </p> */}

          <div className="InputSubGroup">
            <HashtagInput
              hashtagData={hashtagData}
              hashtags={hashtags}
              setHashtags={setHashtags}
            />
          </div>
        </div>

        <div className="InputGroup">
          {/* <h3>Zusätzliche Infos</h3>
                <p>
                    Es fehlt eine Kategorie bzw. ein Hashtag oder etwas anderes passt nicht? Sag es uns!
                </p> */}
          <div className="InputSubGroup">
            <MsgInput msg={msg} setMsg={setMsg} />
          </div>
        </div>

        {/* <div className="InputGroup">
                <div className="InputSubGroup" style={{"margin-top": "10px"}}>

                <div className="" style={{"margin-right": "10px"}}>
                        <ClientCaptcha captchaCode={code => setCaptchaCode(code)} />
                    </div>
                    <div className="" style={{"margin-right": "10px"}}>
                        <input className="CaptchaInput" type="text" placeholder="captcha" value={captchaInput} onChange={(ev) => setCaptchaInput(ev.target.value)} />
                    </div>
                </div>
                <div className="Input" style={{"margin-right": "10px"}}>
                    <span className="Error">{errors["captcha"]}</span>
                </div>

            </div> */}
        <div className="Submit">
          <h3>Abschicken</h3>
          <div className="InputSubGroup">
            <PrivacyCheckbox
              privacyAccepted={privacyAccepted}
              setPrivacyAccepted={setPrivacyAccepted}
            />
            <div className="Input Element Error">
              <span className="Error">{errors["privacy"]}</span>
            </div>
          </div>
          <input
            type="submit"
            value="Vorfall melden"
            className="vorfall-melden-submit"
          />
          <div className="Input Element Error">
            <span className="Error">{errors["submit"]}</span>
          </div>
        </div>
      </form>
    </>
  );
};

const Report = () => {
  const [incidents, loadingIncidents, loadedIncidents] = useIncidents();
  // const [placeData, loadingPlaceData, loadedPlaceData] = usePlaces();
  const [hashtagData, loadingHashtagData, loadedHashtagData] = useHashtags();

  const [germanyGeoJson, loadingGermanyGeoJson, loadedGermanyGeoJson] =
    useGermanyGeoJson();
  const [statesGeoJson, loadingStatesGeoJson, loadedStatesGeoJson] =
    useStatesGeoJson();
  const [districtsGeoJson, loadingDistrictsGeoJson, loadedDistrictsGeoJson] =
    useDistrictsGeoJson();

  return (
    <>
      <TitleBar img={headerWithText} />
      <div className="Page">
        <TextArticle title="Meldet uns Vorfälle">
          <p>
            Wir recherchieren fortlaufend neue und alte Vorkommnisse von
            Verdachtsfällen und kontroversem Polizeiverhalten für die Datenbank.
            Dabei können allerdings auch einige Vorfälle übersehen werden. Vor
            allem bei der Suche nach älteren Vorkommnissen, zurückgehend bis zum
            Jahr 1990, hoffen wir daher auf Mithilfe und Unterstützung bei der
            Recherche.
          </p>
          {/* <p>aufgrund von fehlender Prominenz oder regional begrenzter
                    Berichterstattung. Gerade bei der Suche nach älteren
                    Vorkommnissen, zurückgehend bis zum Jahr 1990,
                    hoffen wir auf Mithilfe.</p> */}

          <p>
            Bevor ein neuer Vorfall eingetragen wird, kann auf dieser Seite
            unter "Zeitpunkt und Ort" geprüft werden, ob der bereits in der
            Chronik erfasst ist.
          </p>

          {/* <p>
                    Du kannst uns nur eine Quelle schicken oder zusätzlich die weiteren Felder ausfüllen, um uns bei der arbeit zu unterstützen.
                    Alle Felder mit * müssen ausgefüllt werden.
                </p> */}

          {/* <p>(Bitte kontrollieren Sie vorher Mithilfe unserer
                    Suchfunktion, ob wir, Ihren Vorfall, den Sie uns
                    melden wollen, bereits in der Chronik erfasst haben.
                    ) -> würde sowohl auf Sie, als auch auf Du Schreibweise
                    verzichten, deswegen so diese passive Formulierung unten:</p> */}

          <div className="Form Report">
            <LoadingInfo
              loaded={
                loadedIncidents &&
                loadedHashtagData &&
                loadedGermanyGeoJson &&
                loadedStatesGeoJson &&
                loadedDistrictsGeoJson
              }
              loading={
                loadingIncidents ||
                loadingHashtagData ||
                loadingGermanyGeoJson ||
                loadingStatesGeoJson ||
                loadingDistrictsGeoJson
              }
              msg="Sammelt alle Einzelfälle von allen Orten"
            />
            {/* ugly work around to render germanyGeoJson properly */}
            {loadedGermanyGeoJson &&
              loadedStatesGeoJson &&
              loadedDistrictsGeoJson && (
                <Form
                  incidents={incidents}
                  hashtagData={hashtagData}
                  germanyGeoJson={germanyGeoJson}
                  statesGeoJson={statesGeoJson}
                  districtsGeoJson={districtsGeoJson}
                />
              )}
          </div>
        </TextArticle>
      </div>
    </>
  );
};

export default Report;
