import React from "react";
import { Icon } from "icons";
import { useSetFilter } from "context";
import ReactMarkdown from "react-markdown";

const createTagButton =
  ({ className, icon, key }) =>
  ({ item }) => {
    const setFilter = useSetFilter();

    return (
      <span
        className={"TagButton " + (className ? className : "")}
        onClick={() =>
          setFilter &&
          setFilter({ hashtags: (oldHashtags) => [...oldHashtags, item.id] })
        }
      >
        <span className={className + "__Content TagButton__Content"}>
          <Icon name={icon} />
          {item.name}
        </span>
        {item.description && (
          <div className={className + "__Popup Popup"}>
            <div className={className + "__Popup__Content Popup__Content"}>
              <span
                className={
                  className + "__Popup__Content__Text Popup__Content__Text"
                }
              >
                <div className="Title">
                  <Icon name="help" />
                  {item.name}
                </div>
                <ReactMarkdown>{item.description}</ReactMarkdown>
              </span>
            </div>
          </div>
        )}
      </span>
    );
  };

const createTagButtonList =
  ({ className, Component }) =>
  ({ items }) =>
    (
      <div className={"TagButtonList " + (className ? className : "")}>
        {items && items.map((item) => <Component key={item.id} item={item} />)}
      </div>
    );

export const Hashtag = createTagButton({
  className: "Category",
  icon: "category",
  key: "hashtags",
});
export const Hashtags = createTagButtonList({
  className: "Categorys",
  Component: Hashtag,
});
