import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    if (error && error.stack && error.message) {
      return {
        hasError: true,
        error: {
          message: error.message,
          stack: error.stack,
        },
      };
    }
    return {
      hasError: true,
      error: {
        message: error,
        stack: null,
      },
    };
  }

  componentDidCatch(error, errorInfo) {
    // TODO log errors?
  }

  render() {
    if (this.state.hasError) {
      return <this.props.errorComponent error={this.state.error} />;
    }
    return this.props.children;
  }
}

export { ErrorBoundary };
