import React from "react";
import { Icon } from "icons";

/*
        (0, 'Land'),
        (1, 'Bundesland'),
        (2, 'Landkreis'),
        (3, 'Stadt'),
        (4, 'Stadtteil'),
        (5, 'Straße'),
        (6, 'Punktgenau'),
*/

const LocationText = ({ precision, state, community }) => {
  switch (precision) {
    case 0:
      return "Deutschland";
    case 1:
      return <span title={state}>{state}</span>;
    default:
      return (
        <>
          {state && (
            <>
              <span title={state}>{state}</span>,&nbsp;
            </>
          )}
          {community && <span title={community}>{community}</span>}
        </>
      );
  }
};

export const Location = ({
  place,
  state,
  county,
  community,
  postalCode,
  coordinates,
  precision,
}) => (
  <span className="TagButton Location">
    <span className="TagButton__Content Location__Content">
      <Icon name="marker" />
      <LocationText
        precision={precision}
        state={state}
        community={community}
        place={place}
      />
    </span>
  </span>
);
