import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "icons";

export const Footer = () => (
  <div className="FooterWrapper">
    <div className="Footer">
      <div className="footer-content-wrapper">
        <div className="Footer__Block">
          <strong>Copservation &copy; 2020</strong>
        </div>
        <div className="Footer__Block">
          <span>
            <Link to="/faq">Häufig gestellte Fragen</Link>
          </span>
          <span>
            <Link to="/definitions">Kontexte</Link>
          </span>
        </div>
        <div className="Footer__Block">
          <span>
            <Link to="/privacy">Datenschutzerklärung</Link>
          </span>
          <span>
            <Link to="/imprint">Impressum</Link>
          </span>
          <span>
            <Link to="/contact">Kontakt</Link>
          </span>
        </div>
        {/*
                <div className="Footer__Block">
                    <span><Link to="/contributions">Contributions</Link></span>
                </div>
                */}
        <span className="Span">&nbsp;</span>
        <div className="Footer__Block Footer__Block--horizontal">
          <a
            className="SocialMediaIcon"
            href="https://twitter.com/cop_servation"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="twitter" />
          </a>
          <a
            className="SocialMediaIcon"
            href="https://www.instagram.com/copservation/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="instagram" />
          </a>
        </div>
      </div>
    </div>
  </div>
);
