import React, { useEffect, useCallback, useState } from "react";
import {
  ExpandableSection,
  TitleBar,
  TextArticle,
  LoadingInfo,
} from "components";
import { useFAQ } from "../api";
import { scrollToTargetAdjusted } from "utils";
import ReactMarkdown from "react-markdown";
import { headerWithText } from "images";
import { setImmediate } from "utils";

const TextContent = (props) => {
  const { faqEntries } = props;

  const [expanded, setExpandedInner] = useState(
    window.location.hash ? window.location.hash.substring(1) : null,
  );
  const setExpanded = useCallback((id) => {
    setExpandedInner(id);
    if (window.history?.pushState) {
      window.history.pushState(null, null, id === null ? "#" : "#" + id);

      const node = document.getElementById(id);
      if (node) {
        setImmediate(() => scrollToTargetAdjusted(node));
      }
    }
  }, []);

  return (
    <>
      {faqEntries.map((value, index) => {
        return (
          <>
            <ExpandableSection
              id={index.toString()}
              title={value.heading}
              setExpandedSection={(value) => setExpanded(value)}
              expandedSection={expanded}
            >
              <ReactMarkdown>
                {typeof value.content === "string"
                  ? value.content
                  : value.content.join("\n")}
              </ReactMarkdown>
            </ExpandableSection>
          </>
        );
      })}
    </>
  );
};

const FrequentlyAskedQuestions = () => {
  const [faqEntries, loadingFAQ, loadedFAQ] = useFAQ();

  useEffect(() => {
    if (loadedFAQ) {
      if (window.location.hash.length > 1) {
        const id = window.location.hash.substring(1);
        setImmediate(() => {
          const node = document.getElementById(id);
          if (node) {
            scrollToTargetAdjusted(node);
          }
        });
      }
    }
  }, [loadedFAQ]);

  return (
    <>
      <TitleBar img={headerWithText} />
      <div className="Page">
        <LoadingInfo
          loaded={loadedFAQ}
          loading={loadingFAQ}
          msg="Lädt Einträge"
        />
        <TextArticle title="Häufig gestellte Fragen">
          <TextContent faqEntries={faqEntries} />
        </TextArticle>
      </div>
    </>
  );
};

export default FrequentlyAskedQuestions;
