import React from "react";
import LolDatePicker from "react-datepicker";
import { Icon } from "icons";

const range = (start, end, increment) => {
  let a = [];
  for (let i = start; i <= end; i += increment) {
    a.push(i);
  }
  return a;
};

export const DatePicker = ({ value, setValue }) => {
  const years = range(1990, new Date().getUTCFullYear(), 1).reverse();
  const months = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];
  return (
    <div className="DatePicker">
      <LolDatePicker
        dateFormat="dd.MM.yyyy"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>
            <select
              value={date.getUTCFullYear()}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[date.getMonth()]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        )}
        selected={value}
        onChange={setValue}
      />
      {value !== null && (
        <span onClick={() => setValue(null)}>
          <Icon name="TimesCircle" />
        </span>
      )}
    </div>
  );
};
