import React from "react";
import {
  BaseMap,
  Marker,
  LoadingInfo,
  Incident,
  IncidentNotFound,
  SideBar,
} from "components";
import { useIncident } from "api";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";

export const IncidentsMapInner = ({
  incident,
  otherIncidents = [],
  defaultWidth,
  width,
  defaultHeight,
  height,
  secondaryPin = false,
}) => (
  <BaseMap
    key={incident.id}
    center={incident.coordinates.split(",").map((a) => parseFloat(a))}
    zoom={9}
    width={width}
    height={height}
    defaultWidth={defaultWidth}
    defaultHeight={defaultHeight}
    noControl
  >
    <Marker
      anchor={incident.coordinates.split(",").map((a) => parseFloat(a))}
      secondaryPin
    />

    {otherIncidents.map((otherIncident) => (
      <Marker
        key={otherIncident.id}
        position={otherIncident.coordinates
          .split(",")
          .map((a) => parseFloat(a))}
      />
    ))}
  </BaseMap>
);

export const IncidentsMap = (props) => (
  <div className="TitleBar tile">
    <IncidentsMapInner {...props} />
  </div>
);

const IncidentPage = () => {
  const params = useParams();
  const [incident, loadingIncident, loadedIncident] = useIncident(params.id);
  const otherIncidents = [];

  if (!incident) {
    if (loadedIncident) {
      return (
        <>
          <div className="TitleBar"></div>
          <div className="Page">
            <IncidentNotFound />
            <SideBar />
          </div>
        </>
      );
    } else {
      return null;
    }
  }

  return (
    <>
      {/* TODO if image is set <TitleBar/> */}
      <LoadingInfo loading={loadingIncident} loaded={loadedIncident} />
      <IncidentsMap
        incident={incident}
        otherIncidents={otherIncidents}
        defaultWidth="1000"
        height="200"
      />
      <Helmet>
        <title>Copservation - {incident.title}</title>
        <meta name="description" content={incident.teaser} />
        <meta name="theme-color" content="#223f62" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@cop_servation" />
        <meta name="twitter:creator" content="@cop_servation" />
        <meta
          name="twitter:image"
          content={`https://copservation.de/incident/${incident.id}/first.html.png`}
        />
        <meta
          property="og:url"
          content={`https://copservation.de/incident/${incident.id}/`}
        />
        <meta property="og:title" content={incident.title} />
        <meta property="og:description" content={incident.teaser} />
        <meta
          property="og:image"
          content={`https://copservation.de/incident/${incident.id}/fourth.html.png`}
        />
      </Helmet>
      <div className="Page">
        <Incident incident={incident} />
        {/*<h3>Verknüpfte Vorfälle</h3>
            {<Incidents incidents={otherIncidents} paginate={false}/>*/}
        <SideBar>
          {/* TODO add if image is set
                    <IncidentsMap incident={incident}/>
                    */}
        </SideBar>
      </div>
    </>
  );
};

export default IncidentPage;
