import React from "react";
import { Link } from "react-router-dom";

export const IncidentButton = ({ incident }) => (
  <Link
    to={"/incident/" + (incident.slug ? incident.slug : incident.id) + "/"}
    className="TagButton"
  >
    Vorfall: {incident.slug ? incident.slug : incident.id}
  </Link>
);
