import React from "react";
import first from "./1.png";
import second from "./2.png";
import third from "./3.png";
import fourth from "./4.png";
import { IncidentsMapInner } from "../pages/IncidentPage";
import { useIncident } from "api";
import { useParams } from "react-router";
import { IncidentNotFound } from "components";

export const First = ({ incident }) => (
  <>
    <div
      style={{
        position: "relative",
        height: "315px",
        width: "1600px",
        top: "180px",
        zIndex: 5,
      }}
    >
      <IncidentsMapInner
        incident={incident}
        defaultHeight="315"
        defaultWidth="1600"
      />
    </div>
    <img
      alt="thumbnail background"
      src={first}
      style={{ position: "absolute" }}
    />
  </>
);

export const Second = ({ incident }) => (
  <>
    <div
      style={{
        position: "relative",
        height: "314px",
        width: "1600px",
        top: "238px",
        zIndex: 5,
      }}
    >
      <IncidentsMapInner
        incident={incident}
        defaultHeight="314"
        defaultWidth="1600"
      />
    </div>
    <img
      alt="thumbnail background"
      src={second}
      style={{ position: "absolute" }}
    />
  </>
);

export const Third = ({ incident }) => (
  <>
    <div
      style={{
        position: "relative",
        height: "383px",
        width: "1080px",
        top: "313px",
        zIndex: 5,
      }}
    >
      <IncidentsMapInner
        incident={incident}
        defaultHeight="383"
        defaultWidth="1080"
      />
    </div>
    <img
      alt="thumbnail background"
      src={third}
      style={{ position: "absolute" }}
    />
  </>
);

export const Fourth = ({ incident }) => (
  <>
    <div
      style={{
        position: "relative",
        height: "330px",
        width: "1080px",
        top: "291px",
        zIndex: 5,
      }}
    >
      <IncidentsMapInner
        incident={incident}
        defaultHeight="330"
        defaultWidth="1080"
      />
    </div>
    <img
      alt="thumbnail background"
      src={fourth}
      style={{ position: "absolute" }}
    />
  </>
);
export const IncidentThumbnail = ({ version }) => {
  const params = useParams();
  const [incident, , loadedIncident] = useIncident(params.id);

  if (!incident) {
    if (loadedIncident) {
      return (
        <>
          <div className="TitleBar"></div>
          <div className="Page">
            <IncidentNotFound />
          </div>
        </>
      );
    } else {
      return null;
    }
  }

  const versions = {
    first: <First incident={incident} />,
    second: <Second incident={incident} />,
    third: <Third incident={incident} />,
    fourth: <Fourth incident={incident} />,
  };

  return versions[version] || null;
};
