export const locationPrecisions = {
  0: "Land",
  1: "Bundesland",
  2: "Landkreis",
  3: "Stadt",
  6: "Punktgenau",
};

export const datePrecisions = {
  0: { name: "Zeit", days: 0.5 },
  1: { name: "Tag", days: 1 },
  2: { name: "Woche", days: 7 },
  3: { name: "Monat", days: 30 },
  4: { name: "Jahr", days: 365 },
};
