import React from "react";
import { Icon } from "icons";

export const Helper = ({ heading, helpText, children }) => (
  <div className="Help">
    <span className="Help__Icon">
      <Icon name="help" />
    </span>
    <div className="Popup">
      <div className="Popup__Content">
        <span className="Popup__Content__Text">{children}</span>
      </div>
    </div>
  </div>
);
