import React from "react";
import { Link } from "react-router-dom";

export const TabButton = ({ tab, setTab, name, children, to }) => (
  <li
    className={tab === name ? "active" : null}
    onClick={setTab ? () => setTab(name) : null}
  >
    {to && <Link to={to}>{children}</Link>}
    {!to && children}
  </li>
);
