import React from "react";
import ReactDOM from "react-dom";
import "css/index.scss";
import "css/fullscreen.css";
import App from "App";
import * as serviceWorker from "./serviceWorker";
import { LanguageProvider } from "./Translate";
import { AppError, ErrorBoundary } from "components";
import { ApiProvider } from "api";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.hydrate(
  <React.StrictMode>
    <ErrorBoundary errorComponent={AppError}>
      <ApiProvider>
        <Router basename={process.env.REACT_APP_BASE_URL}>
          <LanguageProvider>
            <App />
          </LanguageProvider>
        </Router>
      </ApiProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
