import React from "react";
import { petitionHeader } from "images";
import { A } from "components";

export const PetitionHeader = () => (
  <A
    href="https://weact.campact.de/petitions/deutschland-hat-ein-polizeiproblem-wir-fordern-unabhangige-beschwerdestellen"
    title="Petition"
    withReferrer
    style={{ textDecoration: "none" }}
  >
    <img
      className="TitleBar tile"
      src={petitionHeader}
      title="Petition für Unabhängige Beschwerdestelle"
    />
  </A>
);
