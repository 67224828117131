import React, { useEffect, useMemo, useState, useCallback } from "react";
// Data imports
import { useFilteredIncidents } from "data";
// Component imports
import Translate from "../Translate";
import queryString from "query-string";
import {
  Helper,
  LoadingInfo,
  TabButton,
  IncidentsMap,
  IncidentsList,
  Tab,
  Filter,
  PetitionHeader,
} from "components";
import { useHashtags, useIncidents } from "api";
import moment from "moment";
import { SetFilterProvider } from "context";
import { Icon } from "icons";
import { useParams, useSearchParams, Navigate } from "react-router-dom";
import { useLocation } from "react-router";

const makeUnique = (arry) => {
  return Array.from(new Set(arry.map((i) => i.toString())));
};

const buildSearchPath = ({
  tab,
  citysFilter,
  hashtagsFilter,
  lawsuitsFilter,
  dateRange,
  zoom,
  position,
}) => {
  let p = [];
  /*
    if(citysFilter.length > 0){
        p.push("places=" + citysFilter.map((item) => item.id).join(","));
    }
    */
  if (hashtagsFilter.length > 0) {
    p.push("hashtags=" + hashtagsFilter.join(","));
  }
  if (dateRange[0]) {
    p.push("from=" + moment(dateRange[0]).format("DD-MM-YYYY"));
  }
  if (dateRange[1]) {
    p.push("to=" + moment(dateRange[1]).format("DD-MM-YYYY"));
  }
  if (zoom) {
    p.push("zoom=" + zoom);
  }
  if (position) {
    p.push("pos=" + position);
  }
  return "?" + p.join("&");
};

const parseSearchPath = (path, hashtagsList) => {
  let obj = queryString.parse(path);
  // eslint-disable-next-line eqeqeq
  // TODO obj.places = obj.places ? obj.places.split(",").map((id) => citysList.find((item) => item.id == id)) : [];
  obj.places = [];

  // eslint-disable-next-line eqeqeq
  obj.hashtags = obj.hashtags ? obj.hashtags.split(",") : [];
  // eslint-disable-next-line eqeqeq
  obj.dateRange = [
    obj.from ? new Date(moment(obj.from, "DD-MM-YYYY")) : null,
    obj.to ? new Date(moment(obj.to, "DD-MM-YYYY")) : null,
  ];
  return obj;
};

const getPageFromMatch = (params) => {
  const number = params?.page ? parseInt(params?.page) : 0;
  return number;
};

const Overview = () => {
  // TODO maybe make it changeable
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  const params = useParams();

  const pageSize = 5;
  const [page, setPage] = useState(getPageFromMatch(params));
  const [incidents, loadingIncidents, loadedIncidents] = useIncidents();
  const [hashtagsOr, setHashtagsOr] = useState(true);
  const [hashtagsList, loadingHashtags, loadedHashtags] = useHashtags();
  const searchPath = useMemo(
    () => parseSearchPath(location.search, hashtagsList),
    [location, hashtagsList]
  );
  const tabs = ["map", "text"];
  const tab = tabs.includes(params?.visualizer) ? params.visualizer : "map";
  const hashtagsById = useMemo(
    () =>
      hashtagsList.reduce(
        (a, hashtag) => ({ ...a, [hashtag.id]: hashtag }),
        {}
      ),
    [hashtagsList]
  );

  // Filters
  const [citysFilter, baseSetCitys] = useState(searchPath.places);
  const setCitys = useCallback(
    (value, item) => baseSetCitys(value),
    [baseSetCitys]
  );
  const [baseHashtagsFilter, baseSetHashtags] = useState(searchPath.hashtags);
  const hashtagsFilter = useMemo(
    () =>
      baseHashtagsFilter.map((id) => hashtagsById[id]).filter((item) => !!item),
    [baseHashtagsFilter, hashtagsById]
  );
  const setHashtags = useCallback(
    (value, item) => baseSetHashtags(value.map((item) => item.id)),
    [baseSetHashtags]
  );
  const [lawsuitsFilter, baseSetLawsuits] = useState(searchPath.lawsuits);
  const setLawsuits = useCallback(
    (value, item) => baseSetLawsuits(value),
    [baseSetLawsuits]
  );
  const [dateRange, setDateRange] = useState(searchPath.dateRange);

  useEffect(() => {
    setSearchParams(
      buildSearchPath({
        citysFilter,
        hashtagsFilter: baseHashtagsFilter,
        lawsuitsFilter,
        dateRange,
      })
    );
  }, [
    citysFilter,
    baseHashtagsFilter,
    lawsuitsFilter,
    dateRange,
    setSearchParams,
  ]);

  const setFilter = useCallback(
    ({ hashtags = [], citys = [], lawsuits = [], dateRange = [] }) => {
      if (typeof hashtags === "function") {
        baseSetHashtags(makeUnique(hashtags(baseHashtagsFilter)));
      } else {
        setHashtags(hashtags);
      }

      setCitys(citys);
      setLawsuits(lawsuits);
      setDateRange(dateRange);
    },
    [setCitys, setHashtags, setLawsuits, setDateRange, baseHashtagsFilter]
  );

  const incidentsList = useFilteredIncidents({
    incidents,
    hashtagsOr,
    lawsuits: lawsuitsFilter,
    hashtags: hashtagsFilter,
    citys: citysFilter,
    dateRange,
  });

  // Maybe our page is not correct
  useEffect(() => {
    if (
      loadedIncidents &&
      !loadingIncidents &&
      page * pageSize > incidentsList.length
    ) {
      const currentPage = Math.floor(incidentsList.length / pageSize);
      setPage(currentPage);
    }
  }, [pageSize, page, loadedIncidents, loadingIncidents, incidentsList]);

  const toPaginationLink = useCallback(
    (page) => `/text/${page}/${location.search}`,
    [location.search]
  );

  if (params?.visualizer && !tabs.includes(params.visualizer)) {
    return <Navigate to="/notfound" />;
  }

  return (
    <>
      <LoadingInfo
        loading={loadingIncidents || loadingHashtags}
        loaded={loadedIncidents && loadedHashtags}
      />
      <article className="TitleBar tile">
        <div className="TextBox">
          <p>
            <strong>COPSERVATION</strong> sammelt Berichterstattungen in Print-
            und Onlinemedien zu kontroversem Polizeiverhalten in Deutschland.
            Als Recherchegruppe arbeiten wir öffentlich diskutiertes
            polizeiliches Handeln von 1990 bis heute kartographisch sowie
            chronologisch auf und stellen die Ergebnisse als allgemeines
            Recherchemittel zur Verfügung.
          </p>
        </div>
      </article>
      <PetitionHeader />
      <SetFilterProvider setFilter={setFilter}>
        <div className={"Content Content--" + tab}>
          <Tab className="MapContainer tile" tab={tab} name="map">
            <IncidentsMap incidents={incidentsList} setFilter={setFilter} />
          </Tab>
          <div className="RightBar">
            <div className="sticky">
              <div className="Visualization tile">
                <table style={{ fontSize: "14px" }}>
                  <tr>
                    <td style={{ textAlign: "right" }}>{incidents.length}</td>
                    <td>Fälle gesamt</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>
                      {incidentsList.length}
                    </td>
                    <td>Fälle gefiltert</td>
                  </tr>
                </table>
                <div className="Warning">
                  <Icon name="warning" />
                  <span>Diese Karte ist nicht repräsentativ</span>
                  <Helper>
                    <p style={{ textWrap: "wrap" }}>
                      Da wir aus Kapazitätsgründen nicht alle publik gemachten
                      Fälle erfassen können und auch Medien oft nur über
                      prominente Vorfälle berichten, ist die Karte in ihrer
                      geographischen und quantitativen Darstellung von Vorfällen
                      nicht repräsentativ. Sie spiegelt momentan beispielsweise
                      kein Ost/West- oder Stadt/Land-Verhältnis wider und soll
                      nicht als repräsentiv für die Fallquoten einzelner
                      Bundesländer wahrgenommen werden. An manchen Orten
                      konzentrieren sich die Fälle, während in anderen Regionen
                      kaum Fälle eingetragen wurden. Die Karte wird aber immer
                      umfangreicher.
                    </p>
                  </Helper>
                </div>
              </div>

              <div className="Visualization tile">
                <div className="Title">
                  <h2>
                    <Translate k="overview.visualizationTitle" />
                  </h2>
                </div>
                <ul className="Visualizer">
                  <TabButton
                    tab={tab}
                    name="text"
                    to={`/text/${page}/${location.search}${location.hash}`}
                  >
                    <Translate k="overview.textTab" />
                  </TabButton>
                  <TabButton
                    tab={tab}
                    name="map"
                    to={`/map/${location.search}${location.hash}`}
                  >
                    <Translate k="overview.mapTab" />
                  </TabButton>
                  {/*
                            <TabButton tab={tab} name="graph" to={`/graph/${location.search}${location.hash}`}>
                                <Translate k="overview.graphTab"/>
                            </TabButton>
                            <TabButton tab={tab} name="timeline" to={`/timeline/${location.search}${location.hash}`}>
                                <Translate k="overview.timelineTab"/>
                            </TabButton>
                            */}
                </ul>
              </div>

              <Filter
                incidentCount={incidentsList.length}
                hashtagsOr={hashtagsOr}
                setHashtagsOr={setHashtagsOr}
                citysList={[]}
                setCitys={setCitys}
                citysFilter={citysFilter}
                setLawsuits={setLawsuits}
                lawsuitsFilter={lawsuitsFilter}
                hashtagsList={hashtagsList}
                setHashtags={setHashtags}
                hashtagsFilter={hashtagsFilter}
                setDateRange={setDateRange}
                dateRange={dateRange}
              />
              {tab === "text" && incidentsList.length > 5 && (
                <div style={{ height: "53px" }}>&nbsp;</div>
              )}
            </div>
          </div>
          <Tab className="TextContainer" tab={tab} name="text">
            <IncidentsList
              incidents={incidentsList}
              page={page}
              setPage={setPage}
              toPaginationLink={toPaginationLink}
            />
          </Tab>
        </div>
      </SetFilterProvider>
    </>
  );
};

export default Overview;
