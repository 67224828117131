import React from "react";
import { logo } from "images";
import Navigation from "./Navigation";
import { NavLink } from "react-router-dom";

export const Header = () => (
  <>
    <header className="Header">
      <NavLink className="Banner__Link" to="/">
        <img className="Banner" src={logo} alt="Copservation Banner Text" />
      </NavLink>
      <Navigation />
    </header>
    <div className="Header Hidden">
      <NavLink className="Banner__Link" to="/">
        <img className="Banner" src={logo} alt="Copservation Banner Text" />
      </NavLink>
      <Navigation />
    </div>
  </>
);
