import React, { useRef, useCallback } from "react";
import { Incident } from "./Incident";
import { Link } from "react-router-dom";
import { TextArticle } from "./TextArticle";

function range(start, end) {
  if (end + 1 - start > 0) {
    return [...Array(end + 1 - start).keys()].map((i) => i + start);
  }
  return [];
}

const Page = ({ currentPage, page, setPage, toLink }) => (
  <div
    className={
      "Pagination__Item" +
      (page === currentPage ? " Pagination__Item--active" : "")
    }
    onClick={() => setPage(page)}
  >
    {toLink ? <Link to={toLink(page)}>{page + 1}</Link> : page + 1}
  </div>
);

const Pagination = ({
  page,
  maxPage,
  setPage,
  toLink,
  pageSize = 5,
  paginationSize = 5,
}) => {
  let start = page - paginationSize;
  let end = page + paginationSize;
  if (page + paginationSize >= maxPage - 1) {
    end = maxPage - 1;
    start = page - 2 * paginationSize + (end - page) - 1;
  }
  if (page - paginationSize <= 1) {
    start = 1;
    end = page + 2 * paginationSize - (page - start) + 1;
  }

  if (start <= 0) {
    start = 1;
  }
  if (end >= maxPage) {
    end = maxPage - 1;
  }

  if (end < 0) {
    return null;
  }

  return (
    <div className="Pagination">
      <Page currentPage={page} page={0} setPage={setPage} toLink={toLink} />
      {start - 1 !== 0 && (
        <div className="Pagination__Item">
          <span>&hellip;</span>
        </div>
      )}
      {range(start, end).map((item) => (
        <Page
          key={item}
          currentPage={page}
          page={item}
          setPage={setPage}
          toLink={toLink}
        />
      ))}
      {end + 1 !== maxPage && (
        <div className="Pagination__Item">
          <span>&hellip;</span>
        </div>
      )}
      <Page
        currentPage={page}
        page={maxPage}
        setPage={setPage}
        toLink={toLink}
      />
    </div>
  );
};

export const IncidentsList = ({
  incidents,
  page,
  setPage,
  toPaginationLink,
  paginate = true,
  pageSize = 5,
  paginationSize = 2,
}) => {
  const ref = useRef();
  const setPageAndScroll = useCallback(
    (newPage) => {
      setPage(newPage);
      ref.current.scrollIntoView({ block: "start", behavior: "smooth" });
    },
    [setPage],
  );

  return (
    <div className="Incidents" ref={ref}>
      {incidents
        .slice(page * pageSize, (page + 1) * pageSize)
        .map((incident) => (
          <Incident incident={incident} key={incident.id} />
        ))}
      {paginate && (
        <Pagination
          page={page}
          maxPage={Math.ceil(incidents.length / pageSize - 1)}
          setPage={setPageAndScroll}
          toLink={toPaginationLink}
          pageSize={pageSize}
          paginationSize={paginationSize}
        />
      )}
      {incidents.length === 0 && (
        <TextArticle title="Keine Ergebnisse">
          <p>Für deine aktuelle Filterung wurden keine Ergebnisse gefunden.</p>
        </TextArticle>
      )}
    </div>
  );
};
