import React, { useRef, useCallback, useEffect, useState } from "react";

const Popup = ({ children, closePopup, width, height }) => {
  const ref = useRef();

  // This effect is needed because of https://github.com/facebook/react/issues/5845
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("wheel", (ev) => ev.stopPropagation());
    }
  }, [ref]);

  useEffect(() => {
    let lastMousedown = null;
    const f = (evt) => {
      lastMousedown = new Date();
    };
    const f2 = (evt) => {
      // This might be just a scroll event
      if (
        lastMousedown &&
        new Date().getTime() - lastMousedown.getTime() > 150
      ) {
        return;
      }

      if (!ref.current.contains(evt.target)) {
        closePopup();
      }
    };

    window.addEventListener("mousedown", f);
    window.addEventListener("mouseup", f2);
    return () => {
      window.removeEventListener("mousedown", f);
      window.removeEventListener("mouseup", f2);
    };
  }, [closePopup]);

  return (
    <div
      ref={ref}
      className="marker-popup"
      style={{ maxWidth: width, maxHeight: height }}
    >
      {children}
    </div>
  );
};

export const Marker = ({
  sizeFactor = 0.8,
  topSizeFactor = 0.3,
  bottomSizeFactor = 0.1,
  secondaryPin = false,
  ...props
}) => {
  const [width, height] = [50, 41];
  const [isClicked, setIsClicked] = useState(false);
  const closeHandler = useCallback(() => setIsClicked(false), [setIsClicked]);
  return (
    <div
      style={{
        position: "absolute",
        left: `${props.left}px`,
        top: `${props.top}px`,
        filter: props.hover ? "drop-shadow(0 0 4px rgba(0, 0, 0, .3))" : "",
        cursor: "pointer",
        zIndex: isClicked ? "2" : "1",
        ...(props.style || {}),
      }}
      className={
        props.className
          ? `${props.className} pigeon-click-block`
          : "pigeon-click-block"
      }
      onClick={
        React.Children.count(props.children) &&
        ((ev) => {
          setIsClicked(true);
          if (!isClicked) {
            const point = props.pixelToLatLng([
              props.left + (props.mapState.width * sizeFactor) / 2 - width / 2,
              props.top + props.mapState.height * topSizeFactor - height,
            ]);
            props.setCenterZoom(point);
          }
        })
      }
    >
      <span
        className={
          "customPin " + (secondaryPin || isClicked ? "customPin-clicked" : "")
        }
      />
      {isClicked && (
        <Popup
          closePopup={closeHandler}
          width={props.mapState.width * sizeFactor}
          height={props.mapState.height * (1 - topSizeFactor)}
        >
          {React.Children.map(props.children, (child) =>
            React.cloneElement(child, { closeHandler }),
          )}
        </Popup>
      )}
    </div>
  );
};
