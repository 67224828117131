import React, { memo, useRef, useMemo } from "react";
import moment from "moment";
import queryString from "query-string";
import {
  BaseMap,
  ClusterMarker,
  ClusterHull,
  Incident,
  Marker,
} from "components";
import { SuperCluster } from "pigeon-markercluster";

const IncidentsMapInner = memo(
  ({
    center,
    zoom,
    onClick,
    dateMin,
    dateMax,
    incidents,
    setFilter,
    children,
  }) => (
    <BaseMap
      defaultWidth="670"
      defaultHeight="782"
      defaultCenter={center}
      defaultZoom={zoom}
      onClick={onClick}
    >
      <SuperCluster
        hullComponent={ClusterHull}
        markerComponent={ClusterMarker}
        radius={120}
      >
        {incidents.map((incident) => (
          <Marker
            key={incident.id}
            anchor={incident.coordinates
              .split(",")
              .map((s) => parseFloat(s))
              .reverse()}
          >
            <Incident incident={incident} setFilter={setFilter} isPopup />
          </Marker>
        ))}
      </SuperCluster>
    </BaseMap>
  ),
);

export const IncidentsMap = ({
  incidents,
  center = [51.163375, 10.447683],
  zoom = 6,
  setFilter,
  className,
  children,
  onClick,
}) => {
  let obj = window.location.hash
    ? queryString.parse("?" + window.location.hash.substring(1))
    : null;
  const zoomRef = useRef(obj?.z ? obj.z : zoom);
  const centerRef = useRef(obj?.p ? obj.p.split(",") : center);

  const [dateMin, dateMax] = useMemo(() => {
    let dateMin = undefined;
    let dateMax = undefined;
    for (let incident of incidents) {
      if (dateMin === undefined || dateMin > incident.incident_date) {
        dateMin = incident.incident_date;
      }
      if (dateMax === undefined || dateMax < incident.incident_date) {
        dateMax = incident.incident_date;
      }
    }
    return [moment(dateMin), moment(dateMax)];
  }, [incidents]);

  return (
    <div className={"Map" + (className ? " " + className : "")}>
      <IncidentsMapInner
        center={centerRef.current}
        zoom={zoomRef.current}
        onClick={onClick}
        dateMin={dateMin}
        dateMax={dateMax}
        incidents={incidents}
        setFilter={setFilter}
      >
        {children}
      </IncidentsMapInner>
    </div>
  );
};
