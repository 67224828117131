import React, { useContext, useState } from "react";
import strings from "./data/strings.json";

export const LanguageContext = React.createContext(
  process.env.REACT_APP_DEFAULT_LANGUAGE,
);

export const LanguageProvider = ({ children }) => {
  const [language] = useState(process.env.REACT_APP_DEFAULT_LANGUAGE);

  return (
    <LanguageContext.Provider value={language}>
      {children}
    </LanguageContext.Provider>
  );
};

const Translate = ({ k }) => {
  const language = useContext(LanguageContext);

  return strings && strings[language] && strings[language][k]
    ? strings[language][k]
    : "Undefined";
};

export default Translate;
