import React from "react";
import { A, TitleBar, TextArticle } from "components";
import { headerWithText } from "images";

const TextContent = () => (
  <>
    <p>
      Mit COPSERVATION stellen wir eine umfassende Chronik von Berichten zu
      umstrittenem Polizeiverhalten in der BRD seit 1990 bereit und arbeiten
      diese kartographisch auf. Als eine Gruppe parteiunabhängiger und
      unentgeltlich arbeitender Menschen haben wir es uns zur Aufgabe gemacht,
      Fälle kontroversen polizeilichen Handelns deutschlandweit
      zusammenzuführen. Grundlage für unsere Recherchearbeit sind dabei
      journalistisch aufgearbeitete Artikel und Berichte öffentlich zugänglicher
      Print- und Onlinemedien. Wir erfassen polizeiliches Handeln, das
      Gegenstand öffentlicher Kritik ist, sowie Berichte über Ermittlungs- und
      Disziplinarverfahren und juristische Verurteilungen von
      Polizeibeamt:innen. Die Chronik wird so wertfrei wie möglich gehalten.
    </p>

    <p>
      Als Pressespiegel wollen wir damit eine solide Informationsbasis schaffen,
      auf deren Grundlage polizeikritische Stimmen ihre Argumentation aufbauen
      können. Die aufbereiteten Informationen sollen als öffentliches
      Recherchewerkzeug für Interessierte aus der Zivilgesellschaft sowie
      Journalist:innen, Wissenschaftler:innen, Organisationen und politische
      Gruppen zur Verfügung gestellt werden.
    </p>

    <h4>Hintergründe</h4>

    <p>
      Rechtsextreme Inhalte in Polizeichats, Morddrohungen und Terrorgruppen:
      Bundesweit wird die Frage diskutiert, ob es sich dabei um „Einzelfälle“
      handele oder ob es ein strukturelles Problem innerhalb der deutschen
      Polizei gibt. Es liegt keine umfassende, bundesweite Studie zu
      beispielsweise rassistischer Praxis in den Sicherheitsbehörden vor, obwohl
      diese von vielen gefordert wird. Die Effektivität der dienst- und
      strafrechtlichen Aufarbeitung von polizeilichem Fehlverhalten ist
      umstritten.
    </p>

    <p>
      Von der Polizei ausgeübte Straftaten werden häufig nicht angezeigt und
      Ermittlungen gegen Polizist:innen eingestellt. Das Zwischenergebnis einer
      Studie der Uni Bochum zeigt, dass in 86 Prozent der Fälle von mutmaßlich
      rechtswidriger Polizeigewalt kein Strafverfahren eingeleitet wird{" "}
      <sup>
        <a href="#footnote1">[1]</a>
      </sup>
      . Falls doch, dann enden nur weniger als zwei Prozent der Verfahren
      schließlich vor Gericht{" "}
      <sup>
        <a href="#footnote1">[2]</a>
      </sup>
      . In anderen Bereichen wie bei illegalen Datenabfragen, Falschaussagen
      oder sonstigem Fehlverhalten sind uns bisher keine Studien oder
      statistische Erhebungen bekannt.
    </p>

    <p>
      Die Erfolgsaussichten für Betroffene bei Anzeigen gegen die Polizei sind
      selten vielversprechend. Das Ansehen von Polizist:innen in der Justiz,
      schwierige Beweislagen und Polizist:innen, die in der Praxis selten gegen
      die eigenen Kolleg:innen aussagen (Korpsgeist), spielen dabei eine Rolle.
      Zudem werden im Fall einer Anzeige oft Gegenanzeigen seitens der Polizei
      gestellt. Staatsanwaltschaften, die sonst eng mit der Polizei
      zusammenarbeiten, stehen in der Verantwortung, im Verdachtsfall gegen
      diese Polizeikräfte zu ermitteln. Auch hierbei sind sie auf die
      Ermittlungsergebnisse der Polizei selber angewiesen.
    </p>

    <p>
      Die Bediensteten der Polizei sind als Vollzugsbeamt:innen offiziell dazu
      legitimiert, im Rahmen polizeilicher Maßnahmen Gewalt gegen Personen und
      Gegenstände anzuwenden. Es ist daher notwendig, diese staatliche Struktur
      besonders kritisch unter die Lupe zu nehmen. Eine mangelnde Fehlerkultur,
      teilweise fehlende Kennzeichnungspflichten oder das Fehlen einer
      unabhängigen Kontrollinstanz zur Untersuchung von Fehlverhalten und den
      Missbrauch des Gewaltmonopols erschweren einen ausreichenden Rechtsschutz
      gegen polizeiliche Willkür.
    </p>

    <h4>Perspektiven und Ziele</h4>

    <p>
      Als Teil der kritischen Zivilgesellschaft möchten wir hier ansetzen: Mit
      einer umfassenden Datenbank zu umstrittenem Polizeiverhalten leisten wir
      einen Beitrag zur Situations- und Zustandsbeschreibung der deutschen
      Polizei. Wir vermuten einen strukturell begründeten, problematischen
      Dauerzustand innerhalb der deutschen Polizei, den wir sichtbar machen
      wollen.
    </p>

    <p>
      Dass kritische Medienberichte über polizeiliches Handeln nur die Spitze
      des Eisbergs aufzeigen, ist unstrittig. Darunter befindet sich eine Masse
      von vielen Fällen, welche nie ans Licht der Öffentlichkeit gelangen. Die
      Dunkelziffer wird hoch sein. Zudem sind auf unserer Karte sicherlich nicht
      alle Fälle eingetragen, über die berichtet wurde. Die Karte ist daher in
      ihrer geographischen und quantitativen Darstellung von Fällen nicht
      repräsentativ. Sie spiegelt momentan beispielsweise kein Ost/West- oder
      Stadt/Land-Verhältnis wider und soll nicht als repräsentativ für die
      Fallquoten einzelner Bundesländer wahrgenommen werden.
    </p>

    <p>
      Unsere Datenbank wird aber immer umfangreicher. Sie soll den öffentlichen
      Diskurs über Probleme innerhalb der Polizei mit einer Menge von konkreten
      Beispielen und Inhalten stützen. Wir hoffen, dass die Chronik als
      Recherchewerkzeug viel genutzt und durch die Mithilfe anderer stetig
      erweitert wird.
    </p>
  </>
);

const WhoWeAre = () => (
  <>
    <TitleBar img={headerWithText} />
    <div className="Page">
      <TextArticle title="Was ist COPSERVATION und was machen wir?">
        <TextContent />

        <div className="footnotes">
          <h4>Fußnoten</h4>

          <p id="footnote1">
            <span className="footnote-marker">1.&nbsp;</span>
            <A href="https://news.rub.de/presseinformationen/wissenschaft/2019-09-17-kriminologie-zwischenbericht-im-forschungsprojekt-zu-rechtswidriger-polizeigewalt">
              Zwischenbericht im Forschungsprojekt zu rechtswidriger
              Polizeigewalt
            </A>
            , Ruhr-Universität Bochum, 17. September 2019
          </p>

          <p id="footnote2">
            <span className="footnote-marker">2.&nbsp;</span>
            <A href="https://www.spiegel.de/panorama/justiz/kriminologe-hohe-dunkelziffer-bei-polizeigewalt-in-deutschland-a-1279146.html">
              Hohe Dunkelziffer bei Polizeigewalt in Deutschland
            </A>
            , Spiegel Online, 26. Juli 2019
          </p>
        </div>
      </TextArticle>
    </div>
  </>
);

export default WhoWeAre;
