import React from "react";
import { Icon } from "icons";

/* eslint eqeqeq: "off" */

export const ExpandableSection = ({
  id,
  title,
  children,
  expandedSection,
  setExpandedSection,
}) => (
  <section className="HelpSection" id={id}>
    <div
      className="HelpSection__Title"
      onClick={() => setExpandedSection(expandedSection == id ? null : id)}
    >
      <h3>{title}</h3>
      <Icon name={expandedSection == id ? "compress" : "expand"} />
    </div>

    {expandedSection == id && ( // eslint-disable-line
      <div className="HelpSection__SubSection">{children}</div>
    )}
  </section>
);
