import React from "react";

export const Tab = ({ tab, name, className, children }) => (
  <div
    className={className}
    style={{ display: tab === name ? "flex" : "none" }}
  >
    {children}
  </div>
);
