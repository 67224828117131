import React, { useEffect, useState, useCallback } from "react";
import {
  ExpandableSection,
  TitleBar,
  TextArticle,
  LoadingInfo,
} from "components";
import { useHashtags } from "../api";
import { scrollToTargetAdjusted } from "utils";
import ReactMarkdown from "react-markdown";
import { headerWithText } from "images";
import { setImmediate } from "utils";

const TextContent = ({ data }) => {
  const [expanded, setExpandedInner] = useState(
    window.location.hash ? window.location.hash.substring(1) : null,
  );
  const setExpanded = useCallback((id) => {
    setExpandedInner(id);
    if (window.history?.pushState) {
      window.history.pushState(null, null, "#" + id);

      const node = document.getElementById(id);
      if (node) {
        setImmediate(() => scrollToTargetAdjusted(node));
      }
    }
  }, []);

  const listItems = data.map((data) => (
    <ExpandableSection
      id={data.id.toString()}
      title={data.name}
      setExpandedSection={setExpanded}
      expandedSection={expanded}
    >
      <p>
        <ReactMarkdown>{data.description}</ReactMarkdown>
      </p>
    </ExpandableSection>
  ));

  return <>{listItems}</>;
};

const Definitions = () => {
  const [hashtagData, loadingHashtagData, loadedHashtagData] = useHashtags();

  useEffect(() => {
    if (loadedHashtagData) {
      if (window.location.hash.length > 1) {
        const id = window.location.hash.substring(1);
        setImmediate(() => {
          const node = document.getElementById(id);
          if (node) {
            scrollToTargetAdjusted(node);
          }
        });
      }
    }
  }, [loadedHashtagData]);

  return (
    <>
      <TitleBar img={headerWithText} />
      <LoadingInfo
        loaded={loadedHashtagData}
        loading={loadingHashtagData}
        msg="Sammelt alle Kategorien & Kontexte"
      />

      <div className="Page Page--topMargin">
        <TextArticle title="Kontexte">
          <TextContent data={hashtagData} />
        </TextArticle>
      </div>
    </>
  );
};

export default Definitions;
