import React from "react";
import { Icon } from "icons";
import { DateTime } from "./DateTime";
import moment from "moment";

export const FilterDateTime = ({ date, precision, clickHandler }) => (
  <span
    className="TagButton Date"
    onClick={
      clickHandler &&
      ((ev) =>
        clickHandler({
          dateRange: [
            moment(date)
              .subtract(process.env.REACT_APP_DAY_DIFFERENCE, "d")
              .format("YYYY-MM-DD"),
            moment(date)
              .add(process.env.REACT_APP_DAY_DIFFERENCE, "d")
              .format("YYYY-MM-DD"),
          ],
        }))
    }
  >
    <span className="TagButton__Content Date__Content">
      <Icon name="calendar" />
      <DateTime date={date} precision={precision} />
    </span>
  </span>
);
