import React from "react";
import {
  Map,
  FullscreenControl,
  ControlContainer,
  ZoomControl,
} from "pigeon-maps";

const tiler = (x, y, z, r) =>
  `https://a.basemaps.cartocdn.com/rastertiles/voyager/${z}/${x}/${y}${r}.png`;

export const BaseMap = ({
  noControl = false,
  center = [51.163375, 10.447683],
  zoom = 6,
  ...props
}) => (
  <Map zoom={zoom} defaultCenter={center} {...props} provider={tiler}>
    {!noControl && (
      <ControlContainer
        className="pigeon-control-container"
        style={{ zIndex: 100 }}
      >
        <ZoomControl />
        <FullscreenControl />
      </ControlContainer>
    )}
    {props.children}
  </Map>
);
