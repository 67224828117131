import React from "react";
import { Multiselect } from "multiselect-react-dropdown";
import Translate from "Translate";
import { Helper, DatePicker, PlaceSearchBox } from "components";
import { Icon } from "icons";

export const Filter = ({
  hashtagsOr,
  setHashtagsOr,
  citysList,
  setCitys,
  citysFilter,
  hashtagsList,
  setHashtags,
  hashtagsFilter,
  setDateRange,
  dateRange,
}) => (
  <div className="Filters tile">
    <div className="Filters__Title">
      <h2>
        <Translate k="filter.title" />
      </h2>
    </div>

    {/* hashtags filter */}
    <article className="Filter">
      <label htmlFor="filter-hashtags_input" className="Filter__Title">
        Kontexte
        <span
          className={"SpecialToggle" + (hashtagsOr ? " SpecialToggle--on" : "")}
          onClick={(ev) => {
            ev.preventDefault();
            setHashtagsOr(!hashtagsOr);
          }}
        >
          Oder &nbsp;
          <Icon name="toggleOn" />
          Und &nbsp;
        </span>
        <Helper>
          <p>
            Wähle hier aus, ob nur Fälle angezeigt werden sollen, die{" "}
            <strong>alle</strong> ausgewählten Kontexte enthalten (und) oder
            alle Fälle, die <strong>wenigstens einen</strong> der ausgewählten
            Kontexte enthalten (oder).
          </p>
        </Helper>
        <Icon name="category" />
      </label>
      <div className="Filter__Content">
        <Multiselect
          selectedValues={hashtagsFilter}
          options={hashtagsList}
          displayValue="name"
          onSelect={setHashtags}
          onRemove={setHashtags}
          placeholder="Wähle Kontext"
          id="filter-hashtags"
        />
      </div>
    </article>

    {/* cities filter */}
    <article className="Filter">
      <label htmlFor="filter-cities_input" className="Filter__Title">
        <Translate k="filter.citysTitle" />
        <Icon name="marker" />
      </label>
      <div className="Filter__Content">
        <PlaceSearchBox
          selectedValues={citysFilter}
          options={citysList}
          displayValue="name"
          onSelect={setCitys}
          onRemove={setCitys}
          placeholder="Suche Ort/Stadt"
          id="filter-cities"
        />
      </div>
    </article>

    {/* daterange filter */}
    <article className="Filter">
      <label htmlFor="filter-date--from" className="Filter__Title">
        <Translate k="filter.dateRangeTitle" />
        <Icon name="calendar" />
      </label>
      <div className="Filter__Content">
        <div className="filter__date-wrapper">
          {/*<input type="date"
                 id="filter-date--from"
                 value={dateRange[0] ? dateRange[0] : ""}
                 onChange={(ev) => {
                   const {value} = ev.target;
                   setDateRange((old) => [value, old[1]]);
                 }} />*/}
          <DatePicker
            value={dateRange[0] ? dateRange[0] : null}
            setValue={(value) => setDateRange((old) => [value, old[1]])}
          />
          &nbsp;&nbsp;&nbsp;
          <DatePicker
            value={dateRange[1] ? dateRange[1] : null}
            setValue={(value) => setDateRange((old) => [old[0], value])}
          />
          {/*
          <input type="date"
                 id="filter-date--to"
                 value={dateRange[1] ? dateRange[1] : ""}
                 onChange={(ev) => {
                   const {value} = ev.target;
                   setDateRange((old) => [old[0], value]);
                 }} />
         */}
        </div>
        <small className="filter__small-description">
          <span>von</span>
          <span>bis</span>
        </small>
      </div>
    </article>

    {(citysFilter.length ||
      hashtagsFilter.length ||
      dateRange[0] ||
      dateRange[1]) && (
      <div
        className="Button Button--Fake right"
        onClick={() => {
          setCitys([]);
          setHashtags([]);
          setDateRange([null, null]);
        }}
      >
        <Icon name="trash" />
        Alle Filter zurücksetzen
      </div>
    )}
  </div>
);
