import { useEffect } from "react";

import { useLocation } from "react-router-dom";

export function scrollToTargetAdjusted(element, extraOffset = 10) {
  const headers = document.getElementsByTagName("header");
  const header = headers ? headers[0] : null;

  const headerOffset = (header ? header.offsetHeight : 45) + extraOffset;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition - headerOffset + window.pageYOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
}

// https://stackoverflow.com/a/61602724
export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const setImmediate = (fn) => setTimeout(fn, 0);
