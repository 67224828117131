import React from "react";
import Moment from "react-moment";

export const DateTime = ({ date, precision }) => {
  switch (precision) {
    case 1:
      return <Moment date={date} format="DD.MM.YYYY" />;
    case 2:
      return (
        <>
          <Moment date={date} format="DD.MM.YYYY" /> ± 1 Woche
        </>
      );
    case 3:
      return <Moment date={date} format="MM.YYYY" />;
    case 4:
      return <Moment date={date} format="YYYY" />;
    case 0:
    default:
      return <Moment date={date} format="DD.MM.YYYY - HH:mm" />;
  }
};
