import React from "react";
import { A, TextArticle, TitleBar } from "components";
import { headerWithText } from "images";

const ReportSuccess = () => {
  return (
    <>
      <TitleBar img={headerWithText} />
      <div className="Page">
        <TextArticle title="Kontakt">
          <p>
            Du kannst uns per Mail unter{" "}
            <span className="Mono">kontakt@copservation.de</span> erreichen.
          </p>
          <p>
            Am liebsten bekommen wir verschlüsselte Mails. Wenn es dir möglich
            ist, nutze Bitte den folgenden öffentlichen PGP-Schlüssel beim
            Schreiben der Mail. Der Fingerprint vom{" "}
            <A href="/OpenPGP_0x23EA649C5874665C.asc">Schlüssel</A> lautet{" "}
            <span className="Mono">0x23EA649C5874665C</span>
          </p>

          <div className="Keyblock">
            <pre>
              -----BEGIN PGP PUBLIC KEY BLOCK-----
              <br />
              <br />
              xsDNBGE2kYcBDADPwglr5NE73ZjKTWJYuSJK/a9RGxWyXz+dat/HMG2yHogDKuN5Xhy5omIizr/n
              <br />
              zmnJWwvkhYwW5pl9z+WLqL1JZmwBEiU38vLvcy22DurQRqq1T+LUUyEA9PZ10HLL/pYqT2ak+r7S
              <br />
              5rRgtJdMIGORlZadbTZXe/oIMbruiLcPHumMrnpQYzQdp6ZRbDLbL5pAO1a+N2zGYyWsfLrcFCtJ
              <br />
              nKflPoCCAHx/AAg6fZ76ll6edmKh63gQBnu1qBfEAyGqgzg/F07/BKZFUj3rW7XeW9KUcnr2ZO6s
              <br />
              xdSVFpcsNUEW7769X2FrdDhJUh9JaYeiwafNsddUWUGv35B1V/5qz9GP6yJmqW/AIX7UlHGws8Du
              <br />
              wnjNx5RPUuuP0zI392X8qqxwgzePf1anuu8XprnHgZJoX1aEO6T6AjRc7hmGNme5QVTIQBBLNkPm
              <br />
              s+HPxFqVY4Qc1AnzJaCAEMEFB3nZeZ2FzlgAeCe75TD2RHU0vXsavYI7Zy9SSgm7Ms+DKzUAEQEA
              <br />
              Ac0mY29wc2VydmF0aW9uIDxrb250YWt0QGNvcHNlcnZhdGlvbi5kZT7CwQ8EEwEIADkWIQQZWX1g
              <br />
              nH3hPzC7u9oj6mScWHRmXAUCYTaRiAUJBaOagAIbAwULCQgHAgYVCAkKCwIFFgIDAQAACgkQI+pk
              <br />
              nFh0Zlz/wgwAwkArsctZE9xawSXyGOl6BVi7lTA/Kx37o06MCRoG7JeJmojPI6wDyTGLfqn+IxoX
              <br />
              ZRXeOD9KXWix6kzhZyi0wrq+w1wzQKEcz0TeReHit9k86KJbqeSKqqwDB/kXKG+eSRyqeGtnPTy4
              <br />
              9/tfHYFwy5AeLI02oMtyhttDKVIUguN035FuDZIbKzZdGl3Hs5da4kDO3I3h4i+LESXMZDdqRqrI
              <br />
              3aIIsv/T3yruJhmCF/e0fK6qfQD4q2ntNxeNEaZyLSIlPlTdM++ztN8iSokda3uzvEmV9Fj+LLU4
              <br />
              CSXnVYxS0IRwCqjO/FvV5ajXjTOVWnDIdpwjTiWSoYaXVDLKdq0VzqieL0G8JxSx9/B4h6Lp/GvQ
              <br />
              +PtL7o8l3szCw0thdU/8GBHqGLVnyY//TMRewQatsEym04062mnpx6Xy2pFzzIUoL3xJxtRpp7DW
              <br />
              EftL0EdmwE6HPkzSsY0CoEGP6rf0BQCZ2FxOYojh4poyLGfDvs589E6381jayIA5qVgezsDNBGE2
              <br />
              kYgBDACw9HRpsDS3UNwqUr9EZqrVb2txiSI7XyHu1Y7TvTcMe2TbY0LF3WrQy8iCJQIfVWScEbXB
              <br />
              fvxU/2lfLtbaAz9GmuSivQkINrTTdfcoE7vNg7+ktz7s/64hVaI7TG61BhZGvyErC5YJqTSx4P1R
              <br />
              IVtduOUdEltELJ5ON430ZD3q2sKK6faKz83BKs5pS9GQlwSAIMU4PZTfxOyw2wcGPtszdJVKD5kP
              <br />
              g0Li2rGzO2eF2u4E4Yfp9+DUOeoolX2hRCkvZuA9SpDGUkeTVvckGkrAb6GOi6IydA6cH809PBUn
              <br />
              e0SOztTUMyWbPA5O4SqAalr7GjTQLMZpPHeiUO5UR5LPfux6IIpmD9eI1upFRFVjXWDUbTLaCuvv
              <br />
              EM6IOuoIOzrw+wXOYjsmYwUK1ChrfPFNUpoM1iYDCXOLXV4ZQcox5q35VzZVUV5cuQEW2r6WLzNu
              <br />
              81ebZ7ot0t6K18ZM/mCpYOoTDNjSSUHBBI43oDFLMUNk9J9pkSwsL2oUYklAmGsAEQEAAcLA/AQY
              <br />
              AQgAJhYhBBlZfWCcfeE/MLu72iPqZJxYdGZcBQJhNpGJBQkFo5qAAhsMAAoJECPqZJxYdGZcVW0L
              <br />
              /ibmg+mmZfpjFWYYQ/4wKKQ6uSCiFgvOqZA1F4kviCxkiY0cBQ1FkY7WYJHSm+NVgeHnvHM+c6Dx
              <br />
              r6f3kQ5UrGLolH2dCy6KJdFIppp58rULCDiQWyKLR7ySEWE5iNGZkfhNLRCIOksWDiRKAhcmeBu6
              <br />
              NJiggpi+RkzaOpdQnJBej3zvHKrPaFozysj2ig+1FJ0ttI/7S/TjkH7ATL1fEh1mO8kjkMrYXsLK
              <br />
              TjwrTKYSYqZtZwHm2ADul5aTRE4m/nVSQGCZBnXya5wkhliKmWUZ72JFO471INoSZWTkisLk4Wqe
              <br />
              1UqEsZx2mOayp8YA02tSJMabexWCxylsZZPKFoNvXUnE4NG/ii5TcQRBdXBVWBpglt4tIGFZEyjC
              <br />
              MGlXvXvu+tlSGHegYE22L4QnAnXaErA3NHzFmf2pUswrqe9KbZxMLWuNSqo0CQ9j91llwYPGjfLK
              <br />
              /0qAW/npfgkhu9ZbAz5vF/UW6y+jcYALhaP1W1kT1KhkmxNdMpk8CSiojQ==
              <br />
              =BDbo
              <br />
              -----END PGP PUBLIC KEY BLOCK-----
              <br />
            </pre>
          </div>
        </TextArticle>
      </div>
    </>
  );
};

export default ReportSuccess;
