import hashtags from "./hashtags.json";

export const hashtagsList = Object.keys(hashtags)
  .reduce((a, key) => [...a, hashtags[key]], [])
  .sort((a, b) => a.name.localeCompare(b.name));

export { useFilteredIncidents } from "./useFilteredIncidents";
export {
  usePlaceFromLocation,
  distance,
  distanceFromArrays,
} from "./usePlaceFromLocation";
export { hashtags };
