import React, { useState, useEffect } from "react";
import { GeoJson, Map } from "pigeon-maps";

const tiler = (x, y, z, r) =>
  `https://a.basemaps.cartocdn.com/rastertiles/voyager/${z}/${x}/${y}${r}.png`;

export const StatesMap = ({
  center = [51.163375, 10.447683],
  zoom = 6,
  onMouseOver = () => null,
  onMouseOut = () => null,
  styleCallback,
  className,
  children,
  onClick,
  type = "states",
}) => {
  const [state, setState] = useState(null);
  useEffect(() => {
    fetch(process.env.REACT_APP_DATA_URL + `/geo/${type}.geo.json`)
      .then((response) => response.json())
      .then((response) => {
        setState(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [type]);

  return (
    <div className="StatesMap">
      <Map
        defaultCenter={center}
        defaultZoom={zoom}
        className="map"
        height="300"
        provider={tiler}
      >
        {children}

        {state && (
          <GeoJson
            onClick={onClick}
            svgAttributes={{ fill: "blue", strokeWidth: 3, stroke: "red" }}
            data={state}
            style={{ position: "absolute" }}
            styleCallback={styleCallback}
          />
        )}
        {children}
      </Map>
    </div>
  );
};
