import { useMemo } from "react";
import moment from "moment";
//import incidents from "./incidents.json";

export const useFilteredIncidents = ({
  incidents = [],
  hashtagsOr = true,
  lawsuits = [],
  hashtags = [],
  citys = [],
  dateRange = null,
}) =>
  useMemo(() => {
    const lawsuitNames = lawsuits.map((lawsuit) => lawsuit.id);
    const hashtagsList = hashtags.map((hashtag) =>
      hashtag.id ? hashtag.id : hashtag,
    );
    const from = dateRange && dateRange[0] ? moment(dateRange[0]) : null;
    const to =
      dateRange && dateRange[1] ? moment(dateRange[1]).add(1, "days") : null;

    return incidents.filter((incident) => {
      if (from && from.isSameOrAfter(incident.incident_date)) {
        return false;
      }
      if (to && to.isSameOrBefore(incident.incident_date)) {
        return false;
      }

      if (hashtagsList.length > 0) {
        if (hashtagsOr) {
          let included = false;
          for (let hashtag of incident.hashtags) {
            if (hashtagsList.includes(hashtag.id)) {
              included = true;
              break;
            }
          }
          if (!included) {
            return false;
          }
        } else {
          const incidentHashtags = incident.hashtags.map((item) => item.id);
          for (let hashtag of hashtagsList) {
            if (!incidentHashtags.includes(hashtag)) {
              return false;
            }
          }
        }
      }

      if (citys.length > 0) {
        let included = false;

        const keys = ["state", "county", "community", "postal_code"];
        for (let city of citys) {
          let isCity = true;
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            if (!city[i]) {
              break;
            }
            if (city[i] !== incident[key]) {
              isCity = false;
              break;
            }
          }

          if (isCity) {
            included = true;
            break;
          }
        }

        if (!included) {
          return false;
        }
      }
      if (lawsuits.length > 0) {
        if (
          incident.lawsuit &&
          !lawsuitNames.includes(incident.lawsuit.status)
        ) {
          return false;
        }
      }

      return true;
    });
  }, [hashtagsOr, hashtags, citys, lawsuits, dateRange, incidents]);
