import React from "react";
import { Icon } from "icons";

const cut = (s) => {
  const x = s.match(/https?:\/\/(www\.)?([^.]*)/);
  return x && x.length ? x[2] : "LINK";
};

export const ArticleButton = ({ article }) => (
  <a
    href={article.link.location}
    title={article.link.location}
    className="ArticleButton TagButton"
    target="_blank"
    rel="noopener noreferrer"
  >
    <span className="ArticleButton__Content TagButton__Content">
      <Icon name="news" />
      {article.link && cut(article.link.location)}
    </span>
  </a>
);
