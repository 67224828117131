import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faExclamationCircle,
  faToggleOn,
  faToggleOff,
  faExclamationTriangle,
  faAngleDown,
  faAngleUp,
  faQuestionCircle,
  faTimesCircle,
  faCalendar,
  faNewspaper,
  faHashtag,
  faBalanceScale,
  faMapMarker,
  faCircleNotch,
  faSitemap,
  faFileAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";

const icons = {
  category: faSitemap,
  hashtag: faHashtag,
  marker: faMapMarker,
  lawsuit: faBalanceScale,
  calendar: faCalendar,
  news: faNewspaper,
  loading: faCircleNotch,
  help: faQuestionCircle,
  TimesCircle: faTimesCircle,
  expand: faAngleDown,
  compress: faAngleUp,
  instagram: faInstagram,
  twitter: faTwitter,
  error: faExclamationTriangle,
  teaser: faFileAlt,
  envelope: faEnvelope,
  toggleOn: faToggleOn,
  toggleOff: faToggleOff,
  warning: faExclamationCircle,
  trash: faTrash,
};

export const Icon = ({ name, onClick, spin = false }) => {
  const icon = icons[name];

  return (
    <FontAwesomeIcon
      icon={icon ? icon : faSitemap}
      spin={spin}
      onClick={onClick}
    />
  );
};
