import React, { useEffect, useRef, useMemo, useState } from "react";
import { usePlaces } from "api";
import { Icon } from "icons";

export const PlaceSearchBox = ({ placeholder, onSelect }) => {
  // eslint-disable-next-line
  const [places, loadingPlaces, loadedPlaces] = usePlaces();

  const cities = useMemo(
    () =>
      // Object.keys(places.reduce((a, item) => ({...a, [item[0] +"#" +  item[1] + "#" + item[2]]: true}), {})).map((key) => key.split("#")).map(a => [...a, ""])
      places.map((item) => [item[0], item[1], item[2], ""]),
    [places],
  );

  const states = useMemo(
    () =>
      Object.keys(
        places.reduce((a, item) => ({ ...a, [item[0]]: true }), {}),
      ).map((key) => [key, "", "", ""]),
    [places],
  );
  const landkreis = useMemo(
    () =>
      Object.keys(
        places.reduce(
          (a, item) => ({ ...a, [item[0] + "#" + item[1]]: true }),
          {},
        ),
      )
        .map((key) => key.split("#"))
        .map((a) => [...a, "", ""]),
    [places],
  );
  const plzs = useMemo(() => places.filter((item) => item[3]), [places]);

  const is_num_str = (s) => s.match(/^[0-9]+$/) != null;

  const [values, setValues] = useState([]);
  const [stringValue, setStringValue] = useState("");
  const foundStates = useMemo(
    () =>
      states.filter((item) =>
        item[0].toLowerCase().includes(stringValue.toLowerCase()),
      ),
    [states, stringValue],
  );
  const foundCitys = useMemo(
    () =>
      cities.filter(
        (item) =>
          item[2] && item[2].toLowerCase().includes(stringValue.toLowerCase()),
      ),
    [cities, stringValue],
  );
  const foundLandkreis = useMemo(
    () =>
      landkreis.filter(
        (item) =>
          item[1] && item[1].toLowerCase().includes(stringValue.toLowerCase()),
      ),
    [landkreis, stringValue],
  );
  const foundPlzs = useMemo(() => {
    if (is_num_str(stringValue)) {
      console.log(
        plzs.filter((item) => item[3] && item[3].includes(stringValue)),
      );
      return plzs.filter((item) => item[3] && item[3].includes(stringValue));
    } else {
      return [];
    }
  }, [plzs, stringValue]);

  // https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
  function uniq(a) {
    var seen = {};
    return a.filter(function (item) {
      return seen.hasOwnProperty(item) ? false : (seen[item] = true);
    });
  }

  const results = useMemo(() => {
    const states_set = new Set(foundStates.map((item) => item[0]));

    const filtered_landkreise = foundLandkreis.filter(function (item) {
      return states_set.has(item[1]) ? false : true;
    });

    const landkreis_set = new Set(filtered_landkreise.map((item) => item[1]));

    // console.log(states_set)
    // console.log(landkreis_set)

    const filtered_cities = foundCitys.filter(function (item) {
      return landkreis_set.has(item[2]) || states_set.has(item[2])
        ? false
        : true;
    });

    // console.log('states');
    // console.log(foundStates);
    // console.log('cities');
    // console.log(filtered_cities);
    // console.log('landkreise');
    // console.log(filtered_landkreise);
    // console.log('plz');
    // console.log(foundPlzs);

    return uniq([
      ...foundStates,
      ...filtered_landkreise,
      ...filtered_cities,
      ...foundPlzs,
    ]);
  }, [foundStates, foundCitys, foundLandkreis, foundPlzs]);
  const inputElement = useRef();
  const [showPopup, setShowPopup] = useState(false);
  const [hovered, setHovered] = useState(0);

  useEffect(() => {
    onSelect(values);
  }, [values, onSelect]);

  const render_name_selection = (item) => {
    if (item[3] && item[3] !== "" && item[3] !== null) {
      return item[3] + " (" + item[0] + ")";
    } else if (item[2] !== "") {
      return item[2] + " (" + item[0] + ")";
    } else if (item[1] !== "") {
      return item[1] + " (" + item[0] + ")";
    } else {
      return item[0];
    }
  };

  const render_name_popup = (item) => {
    if (item[3] && item[3] !== "" && item[3] !== null) {
      return (
        <>
          <span className="Plz">{item[3]}</span>
          <span className="State">({item[0]})</span>
        </>
      );
    } else if (item[2] !== "") {
      return (
        <>
          <span className="City">{item[2]}</span>
          <span className="State">({item[0]})</span>
        </>
      );
    } else if (item[1] !== "") {
      return (
        <>
          <span className="County">{item[1]}</span>
          <span className="State">({item[0]})</span>
        </>
      );
    } else {
      return <span className="State">{item[0]}</span>;
    }
  };

  return (
    <div
      className="PlaceSearchBox"
      onClick={(ev) => {
        if (inputElement.current?.focus) {
          inputElement.current.focus();
        }
      }}
    >
      <ul className="Selections PlaceSearchBox__Selections">
        {values.map((item, i) => (
          <li key={i}>
            {render_name_selection(item)}

            <Icon
              name="TimesCircle"
              onClick={(ev) =>
                setValues(values.filter((item2) => item2 !== item))
              }
            />
          </li>
        ))}
      </ul>
      <input
        placeholder={placeholder}
        ref={inputElement}
        type="text"
        value={stringValue}
        onChange={(ev) => setStringValue(ev.target.value)}
        onFocus={(ev) => setShowPopup(true)}
        onBlur={(ev) => setShowPopup(false)}
        onKeyDown={(ev) => {
          if (ev.keyCode === 38) {
            setHovered(hovered - 1);
          } else if (ev.keyCode === 40) {
            setHovered(hovered + 1);
          } else if (ev.keyCode === 13 && results.length > 0) {
            setValues(
              values.includes(results[hovered])
                ? values
                : [...values, results[hovered]],
            );
            setHovered(0);
            setStringValue("");
          }
        }}
      />
      {showPopup && stringValue.trim().length > 0 && (
        <div className="PlaceSearchBox__Popup">
          <ul>
            {results.slice(0, 20).map((item, i) => (
              <li
                key={i}
                className={
                  i % results.length === hovered % results.length
                    ? "active"
                    : null
                }
                onMouseDown={(ev) => {
                  ev.preventDefault();
                  setValues(values.includes(item) ? values : [...values, item]);
                  setStringValue("");
                  setHovered(0);
                }}
              >
                {render_name_popup(item)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
