import React, {
  useMemo,
  createContext,
  useContext,
  useReducer,
  useState,
  useEffect,
} from "react";

const ApiContext = createContext({});

const reducer = (state, action) => {
  return { ...state, ...action };
};

export const ApiProvider = ({ children }) => {
  const [state, setState] = useReducer(reducer, {});
  const stackedState = useMemo(() => ({ state, setState }), [state, setState]);

  return (
    <ApiContext.Provider value={stackedState}>{children}</ApiContext.Provider>
  );
};

export const useIncidents = () => {
  const apiContext = useContext(ApiContext);

  let initialValue = [];
  useEffect(() => console.log(`api[useIncidents]`), []);

  if (window?.__data) {
    if (window.__data["useIncidents"]) {
      initialValue = window.__data["useIncidents"];
    }
  }

  if (apiContext?.state?.incidents) {
    initialValue = apiContext.state.incidents;
  }

  const [incidents, setIncidents] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(initialValue.length > 0);

  useEffect(() => {
    if (!loaded) {
      setLoading(true);
      fetch(process.env.REACT_APP_DATA_URL + "/incidents.json")
        .then((response) => response.json())
        .then((response) => {
          setIncidents(response);
          if (apiContext?.setState) {
            apiContext.setState({ incidents: response });
          }
          setLoading(false);
          setLoaded(true);
        })
        .catch((error) => {
          setLoading(false);
          setLoaded(true);
          console.log(error);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return [incidents, loading, loaded];
};

export const useIncident = (id) => {
  let initialValue = [];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => console.log(`api[useIncident,${id}]`), []);

  if (window?.__data) {
    if (
      window.__data["useIncident"] &&
      window.__data["useIncident"][parseInt(id)]
    ) {
      initialValue.push(window.__data["useIncident"][parseInt(id)]);
    }
  }

  const [incidents, setIncidents] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(initialValue.length > 0);

  useEffect(() => {
    console.log("Effect", loaded, initialValue, id);
    if (!loaded) {
      setLoading(true);
      fetch(process.env.REACT_APP_DATA_URL + "/incidents.json")
        .then((response) => response.json())
        .then((response) => {
          setLoading(false);
          setLoaded(true);
          setIncidents(response);
        })
        .catch((error) => {
          setLoading(false);
          setLoaded(true);
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [
    incidents.find((incident) => incident.id === parseInt(id)),
    loading,
    loaded,
  ];
};

export const usePlaces = () => {
  const apiContext = useContext(ApiContext);
  let initialValue = [];

  if (window?.__data) {
    initialValue = [];
    if (window.__data["usePlaces"]) {
      initialValue = window.__data["usePlaces"];
    }
  }

  if (apiContext?.state?.places) {
    initialValue = apiContext.state.places;
  }

  useEffect(() => console.log(`api["usePlaces"]`), []);
  const [places, setPlaces] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(initialValue.length > 0);

  useEffect(() => {
    setLoading(true);
    fetch(process.env.REACT_APP_DATA_URL + "/places.json")
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        setLoaded(true);
        setPlaces(response);
        if (apiContext?.setState) {
          apiContext.setState({ places: response });
        }
      })
      .catch((error) => {
        setLoading(false);
        setLoaded(true);
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [places, loading, loaded];
};

export const useVictimRepresentations = () => {
  const apiContext = useContext(ApiContext);
  let initialValue = [];

  if (window?.__data) {
    if (window.__data["useVictimRepresentations"]) {
      initialValue = window.__data["useVictimRepresentations"];
    }
  }

  if (apiContext?.state?.victimRepresentations) {
    initialValue = apiContext.state.victimRepresentations;
  }

  useEffect(() => console.log(`api["useVictimRepresentations"]`), []);

  const [victimRepresentations, setVictimRepresentations] =
    useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(initialValue.length > 0);

  useEffect(() => {
    setLoading(true);
    fetch(process.env.REACT_APP_DATA_URL + "/victim_representations.json")
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        setLoaded(true);
        setVictimRepresentations(response);
        if (apiContext?.setState) {
          apiContext.setState({ victimRepresentations: response });
        }
      })
      .catch((error) => {
        setLoading(false);
        setLoaded(true);
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [victimRepresentations, loading, loaded];
};

export const useHashtags = () => {
  const apiContext = useContext(ApiContext);
  let initialValue = [];

  if (window?.__data) {
    if (window.__data["useHashtags"]) {
      initialValue = window.__data["useHashtags"];
    }
  }

  if (apiContext?.state?.hashtags) {
    initialValue = apiContext.state.hashtags;
  }

  useEffect(() => console.log(`api["useHashtags"]`), []);

  const [hashtags, setHashtags] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(initialValue.length > 0);

  useEffect(() => {
    setLoading(true);
    fetch(process.env.REACT_APP_DATA_URL + "/hashtags.json")
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        setLoaded(true);
        setHashtags(response);
        if (apiContext?.setState) {
          apiContext.setState({ hashtags: response });
        }
      })
      .catch((error) => {
        setLoading(false);
        setLoaded(true);
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [hashtags, loading, loaded];
};

export const useFAQ = () => {
  const apiContext = useContext(ApiContext);
  let initialValue = [];

  if (window?.__data) {
    if (window.__data["useFAQ"]) {
      initialValue = window.__data["useFAQ"];
    }
  }

  if (apiContext?.state?.faqContent) {
    initialValue = apiContext.state.faqContent;
  }

  useEffect(() => console.log(`api["useFAQ"]`), []);

  const [faqContent, setFaqContent] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(initialValue.length > 0);

  useEffect(() => {
    setLoading(true);
    fetch(process.env.REACT_APP_DATA_URL + "/faq.json")
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        setLoaded(true);
        setFaqContent(response);
        if (apiContext?.setState) {
          apiContext.setState({ faqContent: response });
        }
      })
      .catch((error) => {
        setLoading(false);
        setLoaded(true);
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [faqContent, loading, loaded];
};

export const useGermanyGeoJson = () => {
  const apiContext = useContext(ApiContext);
  let initialValue = [];

  if (window?.__data) {
    initialValue = [];
    if (window.__data["useGermanyGeojson"]) {
      initialValue = window.__data["useGermanyGeojson"];
    }
  }

  if (apiContext?.state?.germanyGeojson) {
    initialValue = apiContext.state.germanyGeojson;
  }

  useEffect(() => console.log(`api["useGermanyGeoJson"]`), []);
  const [germanyGeoJson, SetGermanyGeoJson] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(initialValue.length > 0);

  useEffect(() => {
    setLoading(true);
    fetch(process.env.REACT_APP_DATA_URL + "/geo/germany.geo.json")
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        setLoaded(true);
        SetGermanyGeoJson(response);
        if (apiContext?.setState) {
          apiContext.setState({ germanyGeojson: response });
        }
      })
      .catch((error) => {
        setLoading(false);
        setLoaded(true);
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [germanyGeoJson, loading, loaded];
};

export const useStatesGeoJson = () => {
  const apiContext = useContext(ApiContext);
  let initialValue = [];

  if (window?.__data) {
    initialValue = [];
    if (window.__data["useStatesGeoJson"]) {
      initialValue = window.__data["useStatesGeoJson"];
    }
  }

  if (apiContext?.state?.statesGeoJson) {
    initialValue = apiContext.state.statesGeoJson;
  }

  useEffect(() => console.log(`api["useStatesGeoJson"]`), []);
  const [statesGeoJson, SetStatesGeoJson] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(initialValue.length > 0);

  useEffect(() => {
    setLoading(true);
    fetch(process.env.REACT_APP_DATA_URL + "/geo/states.geo.json")
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        setLoaded(true);
        SetStatesGeoJson(response);
        if (apiContext?.setState) {
          apiContext.setState({ statesGeoJson: response });
        }
      })
      .catch((error) => {
        setLoading(false);
        setLoaded(true);
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [statesGeoJson, loading, loaded];
};

export const useDistrictsGeoJson = () => {
  const apiContext = useContext(ApiContext);
  let initialValue = [];

  if (window?.__data) {
    initialValue = [];
    if (window.__data["useDistrictsGeoJson"]) {
      initialValue = window.__data["useDistrictsGeoJson"];
    }
  }

  if (apiContext?.state?.districtsGeoJson) {
    initialValue = apiContext.state.districtsGeoJson;
  }

  useEffect(() => console.log(`api["useDistrictsGeoJson"]`), []);
  const [districtsGeoJson, setDistrictsGeoJson] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(initialValue.length > 0);

  useEffect(() => {
    setLoading(true);
    fetch(process.env.REACT_APP_DATA_URL + "/geo/kreise.geo.json")
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        setLoaded(true);
        setDistrictsGeoJson(response);
        if (apiContext?.setState) {
          apiContext.setState({ districtsGeoJson: response });
        }
      })
      .catch((error) => {
        setLoading(false);
        setLoaded(true);
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [districtsGeoJson, loading, loaded];
};
