import React from "react";

export const A = ({ withReferrer = false, ...props }) => (
  <a
    target="_blank"
    rel={"noopener " + (!withReferrer ? "noreferrer" : "")}
    {...props}
  >
    {props.children}
  </a>
);
